import React from "react";
import { Box, useMediaQuery, useTheme } from "@mui/material";
import { Formik } from "formik";
import CustomRadio from "../InputComponents/CustomRadio";
import ButtonWrapper from "../InputComponents/ButtonWrapper";
import { useDispatch } from "react-redux";
import {
  setEditMode,
  setEditOpen,
  setPlanToChange,
} from "../../modules/quote.slice";
import { Title } from "./Title";

export default function SortBt({ handleClose, subtitle }) {
  const dispatch = useDispatch();
  const theme = useTheme();
  const isTablet = useMediaQuery(theme.breakpoints.between("sm", "md"));
  const options = ["Family Plan", "Individual Plan", "Student Plan"].filter(
    (item) => item !== subtitle
  );
  return (
    <Box>
      <Title>Plan Type</Title>
      <Formik
        initialValues={{ plan: "" }}
        onSubmit={(values) => {
          dispatch(setEditOpen(true));
          dispatch(setPlanToChange(values.plan));
          dispatch(setEditMode("change_plan"));
          handleClose();
        }}
      >
        <Box>
          <Box maxHeight={"280px"} overflow={"scroll"}>
            {options?.map((item) => (
              <CustomRadio name="plan" value={item}>
                <spam style={{ fontSize: isTablet ? "16px" : "14px" }}>
                  {item}
                </spam>
              </CustomRadio>
            ))}
          </Box>
          <ButtonWrapper>Update</ButtonWrapper>
        </Box>
      </Formik>
    </Box>
  );
}
