import { Add, Close } from "@mui/icons-material";
import { Box, Button, IconButton, Typography } from "@mui/material";
import { useDispatch } from "react-redux";
import useUrlQuery from "../../../hooks/useUrlQuery";
import {
  removeQuoteFromCompare,
  setShowAddCompareModal,
} from "../../../modules/quote.slice";
import {
  useGetUserDetails,
  useStoreSelectedPlanMutation,
} from "../../../services/quoteService";

const ProductCard = ({ product }) => {
  const { storeSelectedPlan } = useStoreSelectedPlanMutation();
  const { getQuery } = useUrlQuery();
  const enquiry_id = getQuery("enquiry_id");
  const { userDetails } = useGetUserDetails();
  const dispatch = useDispatch();
  const onBuyNow = () => {
    storeSelectedPlan({
      base_premium: product?.data[0].base_premium_amount,
      enquiry_id: enquiry_id,
      ic_id: product?.ic_data[0].id,
      plan_details: product?.data,
      plan_id: product?.data[0].plan_id,
      plan_name: product?.data[0]?.plan_name,
      sum_insured: product?.data[0].sum_insured,
      tax: product?.data[0].tax_amount,
      total_premium: product?.data[0].total_premium_amount,
      alias: product?.ic_data[0].company_alias,
      travel_insurance_id: product?.data[0].travel_insurance_id,
    });
  };
  if (!product) {
    return (
      <>
        <Box
          onClick={() => {
            dispatch(setShowAddCompareModal(true));
          }}
          sx={{
            border: "2px dashed var(--primary-color)",
            borderRadius: "10px",
            height: "100%",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <IconButton
            size="large"
            color="primary"
            sx={{
              border: "1px solid",
              borderColor: "primary.main",
            }}
          >
            <Add />
          </IconButton>
          <Typography fontSize="16px" mt={1}>
            Add Plan
          </Typography>
        </Box>
      </>
    );
  }
  return (
    <>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          border: "1px solid var(--primary-color)",
          borderRadius: "10px",
          p: 1,
          height: "100%",
          gap: 0,
          position: "relative",
          "& img": { height: "50px" },
        }}
      >
        <Box
          sx={{
            position: "absolute",
            right: "0px",
            top: "0px",
            zIndex: 1,
          }}
        >
          <IconButton
            size="small"
            onClick={() => {
              dispatch(removeQuoteFromCompare(product));
            }}
          >
            <Close fontSize="small" />
          </IconButton>
        </Box>
        <Box sx={{ height: "100%" }}>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              height: "60px",
            }}
          >
            <img
              style={{ width: "65px", height: "auto" }}
              src={
                userDetails.company_details[product.ic_data[0].company_alias]
                  .logo
              }
              alt={""}
            />
          </Box>
          <Typography sx={{ fontSize: "12px", my: 0.5, mx: 0 }}>
            {product.data[0]?.plan_name}
          </Typography>
        </Box>
        <Button
          variant="contained"
          color="primary"
          sx={{
            textTransform: "none",
            fontSize: "16px",
            py: 1,
            borderRadius: "6px",
          }}
          onClick={onBuyNow}
        >
          <span style={{ fontFamily: "Inter", paddingRight: 2 }}>₹ </span>{" "}
          {Number(product.data[0].total_premium_amount).toLocaleString("en-IN")}
        </Button>
      </Box>
    </>
  );
};

export default ProductCard;
