/* eslint-disable react-hooks/exhaustive-deps */
import { Box, Button, Grid, Modal, Typography } from "@mui/material";
import { Formik } from "formik";
import { useSendOTP, useVerifyOTP } from "../../../services/inputService";
import { useState } from "react";
import { OtpInput } from "./OTPInput";
import CountdownTimer from "./CountDownTimer";
import ButtonLoader from "../../../components/ButtonLoader/ButtonLoader";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  minWidth: 300,
  maxWidth: 500,
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 3,
  textAlign: "center",
};

const OTPVerificationModal = ({
  mobile: defaultMobile,
  isOtpModalOpen,
  setIsOtpModalOpen,
}) => {
  const [otp, setOtp] = useState("");
  const [timeLeft, setTimeLeft] = useState(5 * 60);
  const [resendTimeLeft, setResendTimeLeft] = useState(30);
  const { sendOtp } = useSendOTP(setTimeLeft);
  const { verifyOTP, isVerifyOtpLoading } = useVerifyOTP();
  const handleClose = () => {
    setOtp("");
    setTimeLeft(5 * 60);
    setResendTimeLeft(30);
    setIsOtpModalOpen(false);
  };
  return (
    <Modal
      open={isOtpModalOpen}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style}>
        <Typography id="modal-modal-title" variant="h6" component="h1">
          OTP Verification
        </Typography>
        <Typography
          id="modal-modal-title"
          variant="body2"
          component="p"
          sx={{ fontSize: ".8rem", mt: 1, color: "#777 !important", m: 3 }}
        >
          Please enter the One-Time Password sent to Mobile Number{" "}
          {defaultMobile?.slice(-4).padStart(defaultMobile.length, "*")}
        </Typography>

        <Formik>
          <Grid container mt={1}>
            <Grid item xs={12} md={12} sx={{ textAlign: "center" }}>
              <OtpInput value={otp} onChange={setOtp} length={6} />
            </Grid>
            <Grid item xs={12} md={12} sx={{ textAlign: "center" }}>
              <Typography
                id="modal-modal-title"
                variant="body2"
                component="p"
                sx={{
                  fontSize: ".8rem",
                  mt: 2,
                  color: "#777 !important",
                  mb: 3,
                }}
              >
                Didn't receive the OTP?{" "}
                {resendTimeLeft <= 0 ? (
                  <span
                    style={{
                      fontSize: ".8rem",
                      color: "var(--primary-color)",
                      cursor: "pointer",
                    }}
                    onClick={() => {
                      sendOtp();
                      setOtp("");
                      setTimeLeft(5 * 60);
                      setResendTimeLeft(30);
                    }}
                  >
                    Resend
                  </span>
                ) : (
                  <span style={{ fontSize: ".8rem" }}>
                    Resend in{" "}
                    <CountdownTimer
                      timeLeft={resendTimeLeft}
                      setTimeLeft={setResendTimeLeft}
                    />
                  </span>
                )}
              </Typography>
            </Grid>
            <Grid item xs={12} md={12}>
              <Button
                onClick={() => otp?.length === 6 && verifyOTP({ otp })}
                sx={{
                  width: "100%",
                  height: "45px",
                  borderRadius: "30px",
                  fontSize: ".8rem",
                  whiteSpace: "nowrap",
                  textTransform: "capitalize",
                  mb: 2,
                }}
                fullWidth
              >
                {isVerifyOtpLoading ? <ButtonLoader /> : "Verify"}
              </Button>
            </Grid>
            <Grid item xs={12} md={12}>
              <Button
                sx={{
                  width: "100%",
                  height: "45px",
                  borderRadius: "30px",
                  fontSize: ".8rem",
                  whiteSpace: "nowrap",
                  textTransform: "capitalize",
                  mb: 2,
                }}
                fullWidth
                onClick={handleClose}
                variant="outlined"
              >
                Cancel
              </Button>
            </Grid>
            <Grid item xs={12} md={12} sx={{ textAlign: "center" }}>
              <Typography
                id="modal-modal-title"
                variant="body2"
                component="p"
                sx={{
                  fontSize: ".8rem",
                  mt: 2,
                  color: "#777 !important",
                  mb: 3,
                  whiteSpace: "nowrap",
                }}
              >
                * This page will automatically expire in :{" "}
                <CountdownTimer
                  timeLeft={timeLeft}
                  setTimeLeft={setTimeLeft}
                  setOtp={setOtp}
                  setIsOtpModalOpen={setIsOtpModalOpen}
                />
              </Typography>
            </Grid>
          </Grid>
        </Formik>
      </Box>
    </Modal>
  );
};

export default OTPVerificationModal;
