import {
  Button,
  Card,
  Divider,
  Skeleton,
  Stack,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import moment from "moment";
import React from "react";
import { useGetUserDetails } from "../../services/quoteService";

export default function HeaderData({ onClick }) {
  const { userDetails, isDetailsLoading } = useGetUserDetails();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));
  const isTablet = useMediaQuery(theme.breakpoints.between("sm", "md"));
  const startDate = moment(userDetails?.trip_start_date, "YYYY-MM-DD").format(
    "DD MMM"
  );
  const endDate = moment(userDetails?.trip_end_date, "YYYY-MM-DD").format(
    "DD MMM"
  );
  const country = userDetails?.travelling_to_country_name;
  const members = Object.keys(userDetails?.member_age_details || {})?.length
    ? Object.keys(userDetails?.member_age_details || {})?.length + 1
    : 1;
  return (
    <>
      {isDetailsLoading ? (
        <>
          <Skeleton variant="rectangular" width="100%" />
        </>
      ) : (
        <Card
          sx={{
            p: isMobile ? 0 : 1,
            pr: isMobile ? 0 : 2,
            pl: isMobile ? 0 : 2,
            m: isMobile ? 0 : 1,
            boxShadow: 0,
            marginRight: isTablet ? "-30px" : isMobile ? "-20px" : 0,
          }}
        >
          <Stack
            justifyContent="space-between"
            alignItems="center"
            direction="row"
            spacing={isMobile ? 0.2 : 1}
            margin
          >
            <Typography
              sx={{
                fontSize: isTablet ? "16px" : isMobile ? "10px" : "12px",
                fontWeight: isMobile ? "600" : "500",
              }}
              variant="h6"
              color={"black"}
            >
              {country && country[0]}
              {country?.length > 1 ? `...  +${country?.length - 1}` : ""}
            </Typography>
            <Divider
              sx={{ background: "black" }}
              orientation="vertical"
              flexItem
            />
            <Typography
              color={"black"}
              sx={{
                fontSize: isTablet ? "16px" : isMobile ? "10px" : "12px",
                fontWeight: isMobile ? "600" : "500",
              }}
              variant="h6"
            >
              {members === 1 ? (
                <>
                  {members} {isMobile ? null : "Member"}
                </>
              ) : (
                <>
                  {members} {isMobile ? null : "Members"}{" "}
                </>
              )}
            </Typography>
            <Divider
              sx={{ background: "black" }}
              orientation="vertical"
              flexItem
            />
            <Typography
              color={"black"}
              sx={{
                fontSize: isTablet ? "16px" : isMobile ? "10px" : "12px",
                fontWeight: isMobile ? "600" : "500",
              }}
              variant="h6"
            >
              {startDate}-{endDate}
            </Typography>
            <Button
              sx={{
                p: 0,
                minWidth: "0px",
                ":hover": {
                  backgroundColor: "#fff",
                },
              }}
              disableRipple
              onClick={onClick}
              variant="text"
            >
              <Typography
                color={
                  isMobile ? "var(--primary-color)" : "var(--primary-color)"
                }
                sx={{
                  fontSize: isTablet ? "16px" : isMobile ? "10px" : "12px",
                  fontWeight: "700",
                }}
                variant="h6"
              >
                Edit
              </Typography>
            </Button>
          </Stack>
        </Card>
      )}
    </>
  );
}
