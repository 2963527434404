/* eslint-disable eqeqeq */
import React from "react";
import * as yup from "yup";
import {
  Box,
  Button,
  Card,
  Grid,
  Paper,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { Form, Formik } from "formik";
import { useDispatch, useSelector } from "react-redux";
import Select from "../../../components/InputComponents/Select";
import TextInput from "../../../components/InputComponents/TextInput";
import { setProposalData } from "../../../modules/proposal.slice";
import { useStoreProposalMutation } from "../../../services/proposalService";
import { upperCase } from "../../../utils/input";
import Title from "../components/FormTitle";
import Summary from "../components/Summary";
import CkycVerified from "../components/CkycVerified";
import { documentOptions } from "../constants/tata.constant";
import Toggle from "../../../components/InputComponents/Toggle";
import FormikSideEffect from "../../../hoc/FormikSideEffect";
import {
  FileUploadContainer,
  FileUploadStyled,
  InputErrorMessage,
} from "../styles/ckyc.styles";

// --------------------------------doc upload base64 function starts-----------------------------//
const convertToBase64 = (file) => {
  if (typeof file === "string") return file;
  if (!file) return "";
  return new Promise((resolve, reject) => {
    const fileReader = new FileReader();
    fileReader.readAsDataURL(file);
    fileReader.onload = () => resolve(fileReader.result);
    fileReader.onerror = (error) => reject(error);
  });
};
// --------------------------------doc upload base64 function ends-----------------------------//
const CkycTata = ({ index }) => {
  const {
    proposalData,
    cKycFailCount,
    cKycStatus,
    primary_proposer_id,
    active,
  } = useSelector((state) => state.proposal);
  const panNumber = proposalData?.proposar?.pan_no;
  const isPanNumber = proposalData?.ckyc_data?.is_pan_no || true;
  const theme = useTheme();
  const dispatch = useDispatch();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const { storeProposal } = useStoreProposalMutation({ index });
  const initialData = {
    doc_options: "aadhaar",
    doc_file: "",
    pan_no: panNumber,
    is_pan_no: cKycFailCount ? !cKycFailCount : isPanNumber,
  };
  const yupValidate = yup.object().shape({
    is_pan_no: yup.boolean().required("This field is required"),
    pan_no: yup.string().when("is_pan_no", {
      is: true,
      then: yup
        .string()
        .required("Pan Number is required")
        .matches(
          /^[A-Z]{3}[PCHFATBLJG]{1}[A-Z]{1}[0-9]{4}[A-Z]{1}$/,
          "PAN Number invalid"
        ),
    }),
    doc_file: yup.mixed().when("is_pan_no", {
      is: false,
      then: yup
        .mixed()
        .test("doc_file", "Please Select the file", (value) => value)
        .test("doc_file", "File is too large", (value) => {
          if (typeof value === "string") {
            const cleanedBase64 = value.replace(/=/g, "");
            const estimatedSize = (cleanedBase64.length * 3) / 4;
            return !estimatedSize / 1024 / 1024 <= 10;
          }
          return value?.size / 1024 / 1024 <= 10;
        })
        .required("Please choose a file"),
    }),
    doc_options: yup.string().when("is_pan_no", {
      is: false,
      then: yup.string().required("This field is required"),
    }),
    aadhaar_no: yup.string().when(["is_pan_no", "doc_options"], {
      is: (is_pan_no, doc_options) => !is_pan_no && doc_options === "aadhaar",
      then: yup
        .string()
        .required("Aadhaar Number is required")
        .matches(
          /^[2-9]{1}[0-9]{3}[0-9]{4}[0-9]{4}$/,
          "Invalid Aadhaar Number"
        ),
    }),
    voter_no: yup.string().when(["is_pan_no", "doc_options"], {
      is: (is_pan_no, doc_options) => !is_pan_no && doc_options === "vid",
      then: yup
        .string()
        .required("Voter ID Number is required")
        .matches(/^[A-Z]{3}[0-9]{7}$/, "Invalid Voter ID Number"),
    }),
    driving_license: yup.string().when(["is_pan_no", "doc_options"], {
      is: (is_pan_no, doc_options) => !is_pan_no && doc_options === "license",
      then: yup.string().required("Driving License required"),
    }),
    ckyc_no: yup.string().when(["is_pan_no", "doc_options"], {
      is: (is_pan_no, doc_options) =>
        !is_pan_no && doc_options === "ckyc_number",
      then: yup
        .string()
        .required("CKYC Number is required")
        .matches(/^[5-9][0-9]{9}$/, "Invalid CKYC Number"),
    }),
    passport_no: yup.string().when(["is_pan_no", "doc_options"], {
      is: (is_pan_no, doc_options) => !is_pan_no && doc_options === "passport",
      then: yup
        .string()
        .required("Passport Number is required")
        .matches(/^[A-Z][0-9]{7}$/, "Invalid Passport Number"),
    }),
  });
  if (index < active) {
    return (
      <Summary title="CKYC Details" index={index}>
        <CkycVerified
          innerText={`${
            proposalData?.proposar?.first_name +
            " " +
            proposalData?.proposar?.last_name
          }, your KYC verification is in progress, please continue with further steps.`}
          isSuccess={false}
        />
      </Summary>
    );
  }
  if (index > active) {
    return (
      <Paper sx={{ m: 2, backgroundColor: "#Fff" }}>
        <Typography fontSize={"22px"} p={2}>
          CKYC Details
        </Typography>
      </Paper>
    );
  }
  return (
    <>
      <Paper sx={{ m: 2, backgroundColor: "#F0F4F5" }}>
        <Formik
          initialValues={initialData}
          onSubmit={async (values) => {
            const doc_file = await convertToBase64(values?.doc_file);
            const payload = {
              ...(values?.is_pan_no && {
                is_pan_no: values?.is_pan_no,
                pan_no: values?.pan_no,
              }),
              ...(!values?.is_pan_no && {
                is_pan_no: values?.is_pan_no,
                doc_type: values.doc_options,
                doc_number:
                  values.passport_no ||
                  values.aadhaar_no ||
                  values.ckyc_no ||
                  values.voter_no ||
                  values.driving_license,
                doc_file,
              }),
            };
            dispatch(setProposalData({ ckyc_data: payload }));
            storeProposal({
              step: 10,
              additional_data: {
                ...proposalData,
                ckyc_data: payload,
              },
              ckyc_data: { ckyc: cKycStatus },
              primary_proposer_id,
              is_proposer: 1,
            });
          }}
          validationSchema={yupValidate}
        >
          {({ errors, setFieldValue, values, setErrors, touched }) => {
            return (
              <div
                style={{
                  backgroundColor: "#f0f4f5",
                  borderRadius: "4px",
                  boxShadow:
                    " 0px 2px 1px -1px rgb(0 0 0 / 20%), 0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%)",
                  transition:
                    " box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
                }}
              >
                <Form>
                  <Card>
                    <Title>CKYC Details</Title>
                    <div className="main" style={{ padding: "10px" }}>
                      <div className="pan">
                        <Grid container sx={{ display: "flex" }}>
                          <Grid
                            item
                            xs={12}
                            sx={{ padding: "16px" }}
                            ml={{ md: 1, xs: 0.5 }}
                          >
                            <FormikSideEffect
                              effect={() => {
                                setErrors({});
                                if (!values?.is_pan_no) {
                                  setFieldValue("aadhaar_no", "");
                                  setFieldValue("driving_license", "");
                                  setFieldValue("ckyc_no", "");
                                  setFieldValue("passport_no", "");
                                  setFieldValue("passport_no", "");
                                } else {
                                  setFieldValue("pan_no", panNumber);
                                }
                              }}
                              dependencies={[values?.is_pan_no]}
                            >
                              <Toggle
                                label="Do you have PAN number?"
                                name={`is_pan_no`}
                              />
                            </FormikSideEffect>
                          </Grid>
                          {values?.is_pan_no ? (
                            <Grid item xs={12} sx={{ padding: "16px" }}>
                              <TextInput
                                name={`pan_no`}
                                values={values?.pan_no}
                                label="PAN Number"
                                inputProps={{ readOnly: true }}
                                onInput={upperCase}
                              />
                            </Grid>
                          ) : (
                            <>
                              <Grid item xs={12} sx={{ padding: "16px" }}>
                                <FileUploadContainer
                                  label="Form 60*"
                                  error={touched?.doc_file && errors?.doc_file}
                                >
                                  <FileUploadStyled
                                    type="file"
                                    name="doc_file"
                                    id="doc_file"
                                    className="form-control"
                                    accept="image/*,.pdf"
                                    onChange={(e) => {
                                      setFieldValue(
                                        "doc_file",
                                        e?.target?.files[0]
                                      );
                                    }}
                                    error={
                                      touched?.doc_file && errors?.doc_file
                                    }
                                  />
                                </FileUploadContainer>
                                {touched?.doc_file && errors?.doc_file && (
                                  <InputErrorMessage>
                                    {errors?.doc_file}
                                  </InputErrorMessage>
                                )}
                              </Grid>
                              <Grid item xs={12} sx={{ padding: "16px" }}>
                                <Select
                                  name={"doc_options"}
                                  label="Select Document"
                                  options={documentOptions}
                                  required
                                />
                              </Grid>
                              {values.doc_options === "aadhaar" && (
                                <Grid item xs={12} sx={{ padding: "16px" }}>
                                  <TextInput
                                    name={`aadhaar_no`}
                                    label="Aadhaar Number"
                                    inputProps={{
                                      minLength: 12,
                                      maxLength: 12,
                                    }}
                                    required
                                  />
                                </Grid>
                              )}
                              {values.doc_options === "license" && (
                                <Grid item xs={12} sx={{ padding: "16px" }}>
                                  <TextInput
                                    name={`driving_license`}
                                    label="Driving License Number"
                                    inputProps={{ maxLength: 12 }}
                                    onInput={upperCase}
                                  />
                                </Grid>
                              )}
                              {values.doc_options === "ckyc_number" && (
                                <Grid item xs={12} sx={{ padding: "16px" }}>
                                  <TextInput
                                    name={`ckyc_no`}
                                    label="CKYC Number"
                                    inputProps={{ maxLength: 12 }}
                                    onInput={upperCase}
                                  />
                                </Grid>
                              )}
                              {values.doc_options === "passport" && (
                                <Grid item xs={12} sx={{ padding: "16px" }}>
                                  <TextInput
                                    name={`passport_no`}
                                    label="Passport Number"
                                    inputProps={{ maxLength: 8 }}
                                    onInput={upperCase}
                                  />
                                </Grid>
                              )}
                              {values.doc_options === "vid" && (
                                <Grid item xs={12} sx={{ padding: "16px" }}>
                                  <TextInput
                                    name={`voter_no`}
                                    label="Voter ID Number"
                                    inputProps={{ maxLength: 12 }}
                                    onInput={upperCase}
                                  />
                                </Grid>
                              )}
                            </>
                          )}
                        </Grid>
                      </div>
                      <Box
                        sx={{
                          display: "flex",
                          justifyContent: "flex-end",
                          alignItems: "center",
                          p: 2,
                        }}
                      >
                        <Button
                          fullWidth={isMobile ? true : false}
                          sx={{
                            textTransform: "none",
                            fontSize: isMobile ? "13px" : "16px",
                            p: 2,
                            pl: 4,
                            pr: 4,
                            borderRadius: 1,
                          }}
                          variant="contained"
                          color="secondary"
                          type="submit"
                        >
                          Proceed to Traveler
                        </Button>
                      </Box>
                    </div>
                  </Card>
                </Form>
              </div>
            );
          }}
        </Formik>
      </Paper>
    </>
  );
};
export default CkycTata;
