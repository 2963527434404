import {
  Card,
  Container,
  Divider,
  FormHelperText,
  Grid,
  Stack,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { Box, InputLabel, Typography } from "@mui/material";
import { Formik } from "formik";
import React, { useState } from "react";
import ButtonWrapper from "../InputComponents/ButtonWrapper";
import MultiSelect from "../InputComponents/MultiSelect";
import DatePicker from "../InputComponents/DatePicker";
import TravelEditRadio from "./components/TravelEditRadio";
import Checkbox from "../InputComponents/Checkbox";
import { useGetCountries, usePatchQuote } from "../../services/inputService";
import { setEditMode } from "../../modules/quote.slice";
import { useDispatch, useSelector } from "react-redux";
import { useGetUserDetails } from "../../services/quoteService";
import moment from "moment";
import useUrlQuery from "../../hooks/useUrlQuery";
import * as yup from "yup";
import { setLeadData, setPlanType } from "../../modules/landing.slice";
import FormikSideEffect from "../../hoc/FormikSideEffect";
import { booleanOptions } from "../../utils/options";
import FlightLandIcon from "@mui/icons-material/FlightLand";
import FlightTakeoffIcon from "@mui/icons-material/FlightTakeoff";
import {
  ChildAndParentSelector,
  SelectDays,
} from "../../pages/InputPage/components";

export default function EditDetails({ handleClose }) {
  const details = useGetUserDetails();
  const { patchQuote } = usePatchQuote();
  const { countries, isCountriesLoading } = useGetCountries();
  const dispatch = useDispatch();
  const userAge = details?.userDetails?.proposer_age;
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));
  const isTablet = useMediaQuery(theme.breakpoints.between("sm", "md"));
  const { getQuery } = useUrlQuery();
  const enquiry_id = getQuery("enquiry_id");
  const { plan_to_change, edit_mode } = useSelector((state) => state.quote);
  const [datePickerOpen, setDatePickerOpen] = useState(false);

  const selectedCountries = details?.userDetails?.travelling_to_country_id?.map(
    (val, index) => {
      return {
        id: val,
        name: details?.userDetails?.travelling_to_country_name[index],
      };
    }
  );
  const { plan_type } = details?.userDetails;
  let planType = plan_type;
  let dataToPatch = {};

  if (edit_mode !== "default") {
    switch (plan_to_change) {
      case "Family Plan":
        dataToPatch = {
          travel_purpose_id: "1",
          plan_type: "2",
        };
        planType = "Floater";
        break;
      case "Individual Plan":
        dataToPatch = {
          travel_purpose_id: "1",
          plan_type: "1",
        };
        planType = "Indivdual";
        break;
      case "Student Plan":
        dataToPatch = {
          travel_purpose_id: "4",
          plan_type: "1",
        };
        planType = "Indivdual";
        break;
      default:
        planType = plan_type;
        break;
    }
  }
  const initialValues = {
    trip_start_date: details?.userDetails?.trip_start_date,
    trip_end_date: details?.userDetails?.trip_end_date,
    max_annual_travel_days: details?.userDetails?.max_annual_travel_days || 30,
    country: selectedCountries,
    multitrip: details?.userDetails?.multitrip || "N",
    agree: "true",
    insured_age: Number(userAge) || "",
    members_age_detail:
      planType !== "Floater"
        ? {}
        : details?.userDetails?.member_age_details || {},
  };

  const validationSchema = yup.object().shape({
    country: yup
      .array()
      .test("country", "Country is required", (value) => value?.length),
    trip_start_date: yup
      .string()
      .nullable()
      .required("Start date is required")
      .test(
        "trip_start_date",
        "Trip start date should be greater than today",
        function (value) {
          return moment(value, "YYYY-MM-DD").isAfter(
            moment().subtract(1, "days")
          );
        }
      )
      .test(
        "trip_start_date",
        "Trip start date should be less than trip end date",
        function (value) {
          return moment(value, "YYYY-MM-DD").isBefore(
            this.parent.trip_end_date
          );
        }
      ),

    trip_end_date: yup.string().nullable().required("End date is required"),
    insured_age: yup.string().required("Insured age is required"),
    ...(planType === "Floater" && {
      members_age_detail: yup
        .object()
        .nullable()
        .test(
          "members_age_detail",
          "Please select at least two member",
          (value) => Object.keys(value || {})?.length >= 1
        ),
    }),
  });
  if (isMobile) {
    return (
      <>
        <Title>Edit Details</Title>
        <Formik
          initialValues={{ ...initialValues, details_id: "1" }}
          validationSchema={validationSchema}
          onSubmit={(values) => {
            handleClose();
            patchQuote({
              ...values,
              ...dataToPatch,
              travel_days:
                values.multitrip === "Y"
                  ? 365
                  : moment(values.trip_end_date).diff(
                      moment(values.trip_start_date),
                      "days"
                    ),
              country_name: values.country.map((item) => item.name),
              travelling_to: values.country.map((item) => item.id),
              enquiry_id,
            });
            if (edit_mode !== "default") {
              dispatch(setPlanType(plan_to_change));
              dispatch(setEditMode("default"));
            } else {
              dispatch(setLeadData(values));
            }
          }}
        >
          {({ values, handleSubmit, handleChange }) => (
            <>
              <Grid sx={{ p: 1.5 }} container spacing={2}>
                <Grid item xs={12}>
                  <Card sx={{ borderRadius: "25px" }}>
                    <Grid justifyContent="center" container spacing={2}>
                      <Grid item xs={6}>
                        <input
                          className="edit-radio"
                          type="radio"
                          name="details_id"
                          value={"1"}
                          id={`travel_details`}
                          checked={values.details_id === "1"}
                          onChange={handleChange}
                        />
                        <label className="purpose" htmlFor={`travel_details`}>
                          <span style={{ marginLeft: 20 }} className="mt-1">
                            Travel Details
                          </span>
                        </label>
                      </Grid>
                      <Grid item xs={6}>
                        <input
                          className="edit-radio"
                          type="radio"
                          name="details_id"
                          value={"2"}
                          id={`indi_details`}
                          checked={values.details_id === "2"}
                          onChange={handleChange}
                        />
                        <label className="purpose" htmlFor={`indi_details`}>
                          <span style={{ marginLeft: 10 }} className="mt-1">
                            Individual Details
                          </span>
                        </label>
                      </Grid>
                    </Grid>
                  </Card>
                </Grid>
                {values.details_id === "1" && (
                  <Container sx={{ mt: 2 }}>
                    <Grid container rowSpacing={1} columnSpacing={1}>
                      <Grid spacing={0} item md={12}>
                        <MultiSelect
                          fullWidth={true}
                          options={countries || []}
                          name="country"
                          isLoading={isCountriesLoading}
                          placeholder="Select Countries"
                        />
                        <Checkbox
                          checked
                          readOnly
                          name="agree"
                          label={
                            <Typography
                              sx={{ fontSize: isTablet ? "16px" : "10px" }}
                            >
                              The traveler(s) are residents of India and the
                              trip should start from India
                            </Typography>
                          }
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <InputLabel sx={{ fontSize: isTablet ? "16px" : "" }}>
                          Date of Travel (Start Date)
                        </InputLabel>
                        <DatePicker
                          onAccept={() => setDatePickerOpen(true)}
                          fullWidth="true"
                          name="trip_start_date"
                          placeholder="Start Date"
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <InputLabel sx={{ fontSize: isTablet ? "16px" : "" }}>
                          Date of Travel (End Date)
                        </InputLabel>
                        <DatePicker
                          name="trip_end_date"
                          open={datePickerOpen}
                          onOpen={() => setDatePickerOpen(true)}
                          onClose={() => setDatePickerOpen(false)}
                          onAccept={() => setDatePickerOpen(false)}
                          disabled={
                            values.multitrip === "Y" || !values.trip_start_date
                          }
                          minDate={
                            values.multitrip !== "Y" &&
                            moment(values.trip_start_date).add(1, "day")
                          }
                          maxDate={
                            values.multitrip !== "Y" &&
                            moment(values.trip_start_date).add(3, "years")
                          }
                          placeholder="End Date"
                        />
                      </Grid>
                      <Grid item md={14}>
                        <TravelEditRadio
                          orientation="vertical"
                          name="multitrip"
                          label="Are you traveling to these countries multiple times in a year?"
                          options={booleanOptions}
                        />
                      </Grid>
                      {values.multitrip === "Y" && (
                        <Grid item md={12}>
                          <SelectDays />
                        </Grid>
                      )}
                    </Grid>
                  </Container>
                )}
                {values.details_id === "2" && (
                  <Container sx={{ mt: 2 }}>
                    <Grid
                      justifyContent="center"
                      container
                      rowSpacing={1}
                      columnSpacing={1}
                    >
                      <Grid item xs={12} md={12}>
                        <ChildAndParentSelector
                          onlySelf={
                            planType === "Indivdual" ||
                            plan_to_change === "Student Plan"
                              ? true
                              : false
                          }
                        />
                      </Grid>
                    </Grid>
                  </Container>
                )}
              </Grid>
              <ButtonWrapper>Update</ButtonWrapper>
            </>
          )}
        </Formik>
      </>
    );
  }
  return (
    <Formik
      initialValues={{ ...initialValues, details_id: "1" }}
      validationSchema={validationSchema}
      onSubmit={(values) => {
        handleClose();
        patchQuote({
          ...values,
          ...dataToPatch,
          travel_frequency_id: values?.multitrip === "Y" ? 2 : 1,
          travel_frequency:
            values?.multitrip === "Y" ? "Annual Multi Trip" : "Single Trip",
          travel_days:
            values.multitrip === "Y"
              ? 365
              : moment(values.trip_end_date).diff(
                  moment(values.trip_start_date),
                  "days"
                ),
          country_name: values.country.map((item) => item.name),
          travelling_to: values.country.map((item) => item.id),
          enquiry_id,
        });
        if (edit_mode !== "default") {
          dispatch(setPlanType(plan_to_change));
          dispatch(setEditMode("default"));
        } else {
          dispatch(setLeadData(values));
        }
      }}
    >
      {({ values, errors, setFieldValue }) => (
        <Grid
          sx={{ maxHeight: "484px" }}
          justifyContent="center"
          container
          spacing={2}
        >
          <Grid item xs={6.2}>
            <Title>Travel Details</Title>
            <Container sx={{ mt: 2 }}>
              <Grid container rowSpacing={2} columnSpacing={2}>
                <Grid item md={12}>
                  <MultiSelect
                    isOptionEqualToValue={(option, value) =>
                      option.name === value.name
                    }
                    options={countries || []}
                    name="country"
                    loading={isCountriesLoading}
                  />
                  <Checkbox
                    checked
                    readOnly
                    name="agree"
                    label="The traveler(s) are residents of India and the trip should start from India"
                  />
                </Grid>
                <Grid item md={6}>
                  <InputLabel>Date of Travel (Start Date)</InputLabel>
                  <DatePicker
                    onAccept={() => setDatePickerOpen(true)}
                    name="trip_start_date"
                    isLanding
                    disablePast
                    placeholder="Start Date"
                    openPickerIcon={FlightTakeoffIcon}
                  />
                </Grid>
                <Grid item md={6}>
                  <InputLabel>Date of Travel (End Date)</InputLabel>
                  <DatePicker
                    name="trip_end_date"
                    open={datePickerOpen}
                    onOpen={() => setDatePickerOpen(true)}
                    onClose={() => setDatePickerOpen(false)}
                    onAccept={() => setDatePickerOpen(false)}
                    disabled={
                      values.multitrip === "Y" || !values.trip_start_date
                    }
                    isLanding
                    minDate={
                      values.multitrip !== "Y" &&
                      moment(values.trip_start_date).add(1, "day")
                    }
                    maxDate={
                      values.multitrip !== "Y" &&
                      moment(values.trip_start_date).add(3, "years")
                    }
                    placeholder="End Date"
                    openPickerIcon={FlightLandIcon}
                  />
                </Grid>
                <Grid item md={12}>
                  <FormikSideEffect
                    effect={() => {
                      if (values.multitrip === "Y") {
                        setFieldValue(
                          "trip_end_date",
                          moment(values.trip_start_date)
                            .add(1, "year")
                            .subtract(1, "day")
                            .format("YYYY-MM-DD")
                        );
                      }
                    }}
                    dependencies={[values.multitrip, values.trip_start_date]}
                  >
                    <TravelEditRadio
                      orientation="vertical"
                      name="multitrip"
                      label="Are you traveling to these countries multiple times in a year?"
                      options={booleanOptions}
                    />
                  </FormikSideEffect>
                </Grid>
                {values.multitrip === "Y" && (
                  <Grid item md={12}>
                    <SelectDays />
                  </Grid>
                )}
              </Grid>
            </Container>
          </Grid>
          <Divider orientation="vertical" flexItem />
          <Grid item xs={5.785}>
            {planType === "Floater" && plan_to_change !== "Student Plan" ? (
              <Title>Family Details</Title>
            ) : plan_to_change === "Student Plan" ? (
              <Title>Student Details</Title>
            ) : (
              <Title>Individual Details</Title>
            )}
            <Container sx={{ mt: 2 }}>
              <Grid
                justifyContent="center"
                container
                rowSpacing={1}
                columnSpacing={1}
              >
                <Grid item md={12}>
                  <ChildAndParentSelector
                    onlySelf={
                      planType === "Indivdual" ||
                      plan_to_change === "Student Plan"
                    }
                  />
                  {errors.members_age_detail && (
                    <FormHelperText
                      sx={{
                        color: "error.main",
                        mt: 2,
                      }}
                    >
                      {errors.members_age_detail}
                    </FormHelperText>
                  )}
                </Grid>
              </Grid>
            </Container>
          </Grid>
          <Grid item xs={12}>
            <ButtonWrapper>Update</ButtonWrapper>
          </Grid>
        </Grid>
      )}
    </Formik>
  );
}

const Title = ({ children, isMobile }) => {
  return (
    <Stack flexDirection="row">
      <Box
        sx={{
          width: "2%",
          border: "2px",
          backgroundColor: "rgba(58, 116, 173, 0.22)",
        }}
      ></Box>
      <Box
        sx={{
          width: "90%",
          backgroundImage: "linear-gradient(98deg, #E8E8E8, white)",
          pl: 2,
          pt: 1,
        }}
      >
        <Typography
          variant="h6"
          sx={{
            fontSize: isMobile ? "13px" : "16px",
            color: "var(--primary-color)",
          }}
          gutterBottom
        >
          {children}
        </Typography>
      </Box>
    </Stack>
  );
};
