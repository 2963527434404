/* eslint-disable eqeqeq */
import React from "react";
import {
  Paper,
  Box,
  Grid,
  Typography,
  Stack,
  useMediaQuery,
  Button,
} from "@mui/material";
import { useSelector } from "react-redux";
import useUrlQuery from "../../../hooks/useUrlQuery";
import { useTheme } from "@emotion/react";
import FooterBuyButton from "./FooterBuyButton";
import download from "../../../assets/images/download.png";
import {
  useGetUserDetails,
  useStoreSelectedPlanMutation,
} from "../../../services/quoteService";

export default function HeaderKM() {
  const { know_more_data } = useSelector((state) => state.quote);
  const { data, ic_data } = know_more_data;
  const { userDetails } = useGetUserDetails();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const { getQuery } = useUrlQuery();
  const { storeSelectedPlan } = useStoreSelectedPlanMutation();
  const enquiry_id = getQuery("enquiry_id");
  const onBuyNow = () => {
    storeSelectedPlan({
      base_premium: data[0].base_premium_amount,
      enquiry_id: enquiry_id,
      ic_id: ic_data[0].id,
      plan_details: data,
      plan_id: data[0].plan_id,
      plan_name: data[0]?.plan_name,
      sum_insured: data[0].sum_insured,
      tax: data[0].tax_amount,
      alias: ic_data[0].company_alias,
      total_premium: data[0].total_premium_amount,
      travel_insurance_id: data[0].travel_insurance_id,
    });
  };
  const { leadData } = useSelector((state) => state.landing);
  const currency = leadData?.currency;
  // const download = () => {
  //   window.open(ic_data[0].travel_broucher);
  // };
  return (
    <Paper sx={{ maxHeight: "100px", boxShadow: 10 }}>
      <Grid
        sx={{ p: 1.5 }}
        justifyContent="center"
        alignItems="center"
        container
        spacing={2}
      >
        <Grid
          sx={{ marginLeft: isMobile ? 0 : 5, mt: isMobile ? 1 : 0 }}
          item
          xs={9.5}
          md={3.8}
        >
          <Grid
            justifyContent="center"
            alignItems="center"
            spacing={2}
            container
          >
            <Grid item xs={2} md={3}>
              <img
                src={
                  userDetails.company_details &&
                  userDetails.company_details[ic_data[0].company_alias].logo
                }
                alt="logo"
                style={{
                  width: isMobile ? "45px" : "90px",
                  height: "auto",
                  maxHeight: "80px",
                }}
              />
            </Grid>
            <Grid item xs={10} md={7}>
              <Stack spacing={1}>
                <Typography
                  sx={{
                    fontSize: isMobile ? "12px" : "16px",
                    fontWeight: "500",
                  }}
                  variant="h3"
                >
                  {ic_data[0].name}
                </Typography>
                <Typography
                  color="rgba(0, 0, 0, 0.7)"
                  fontWeight="600"
                  fontSize={isMobile ? "10px" : "12px"}
                >
                  {data[0]?.plan_name}
                </Typography>
                {isMobile && (
                  <Stack direction="row" spacing={1}>
                    <Typography
                      sx={{
                        fullWidth: true,
                        fontSize: "8px",
                        fontWeight: "400",
                        color: "#a1a1b6",
                      }}
                      variant="body1"
                    >
                      Sum Insured
                    </Typography>
                    <Typography
                      sx={{
                        color: "#1f1f67",
                        fontSize: "16px",
                        fontWeight: "500",
                      }}
                      variant="body1"
                    >
                      € {data[0].sum_insured}
                    </Typography>
                  </Stack>
                )}
              </Stack>
            </Grid>
          </Grid>
        </Grid>
        {isMobile ? null : (
          <Grid item md={7.5}>
            <Grid
              spacing={0}
              justifyContent="center"
              alignItems="center"
              container
              display="flex"
              xs={12}
            >
              <Grid item xs={12} spacing={3} md={4}>
                <Stack sx={{ mr: 3 }} justifyContent="center">
                  <Stack
                    justifyContent="space-between"
                    direction="row"
                    spacing={2}
                  >
                    <Typography
                      sx={{
                        fullWidth: true,
                        fontSize: "16px",
                        fontWeight: "400",
                        color: "var(--primary-color)",
                        marginBottom: 1,
                        width: "63%",
                      }}
                      variant="body1"
                    >
                      Sum Insured :
                    </Typography>
                    {ic_data[0].id == 1 && currency == 2 ? (
                      <Typography
                        sx={{
                          color: "black",
                          fontSize: "16px",
                          fontWeight: "500",
                          width: "37%",
                        }}
                        variant="body1"
                      >
                        € {data[0].sum_insured}
                      </Typography>
                    ) : (
                      <Typography
                        sx={{
                          color: "black",
                          fontSize: "16px",
                          fontWeight: "500",
                          width: "37%",
                        }}
                        variant="body1"
                      >
                        $ {data[0].sum_insured}
                      </Typography>
                    )}
                  </Stack>
                  <Stack direction="row" spacing={2}>
                    <Typography
                      sx={{
                        fullWidth: true,
                        fontSize: "16px",
                        fontWeight: "400",
                        color: "var(--primary-color)",
                        marginBottom: 1,
                        width: "67%",
                      }}
                      variant="body1"
                    >
                      Base Premium :
                    </Typography>
                    <Typography
                      sx={{
                        color: "black",
                        fontSize: "16px",
                        fontWeight: "500",
                        width: "37%",
                      }}
                      variant="body1"
                    >
                      <span style={{ fontFamily: "Inter", fontWeight: "550" }}>
                        ₹
                      </span>{" "}
                      {data[0].base_premium_amount}
                    </Typography>
                  </Stack>
                </Stack>
              </Grid>
              <Grid item md={3.5}>
                <Stack alignItems="center">
                  <Button
                    disableRipple
                    variant="outlined"
                    fontSize="11px"
                    target={"_blank"}
                    download="Brochure"
                    href={ic_data[0].travel_broucher}
                    sx={{
                      backgroundColor: "var(--primary-light)",
                      textTransform: "none",
                      width: "200px",
                      p: 1.5,
                      borderColor: "var(--primary-color)",
                      borderRadius: 2,
                    }}
                  >
                    <span style={{ color: "black" }}>
                      <b>Download Brochure</b>
                    </span>
                    <img
                      style={{ marginLeft: 10 }}
                      src={download}
                      width="15px"
                      alt=""
                    />
                  </Button>
                </Stack>
              </Grid>
              <Grid sx={{ mt: 2 }} item xs={3.5}>
                <Box sx={{ maxWidth: "200px", ml: 5 }}>
                  <FooterBuyButton onClick={onBuyNow}>
                    <span style={{ fontFamily: "Gill-Serif" }}>₹</span>{" "}
                    {data[0].total_premium_amount}
                  </FooterBuyButton>
                </Box>
              </Grid>
            </Grid>
          </Grid>
        )}
      </Grid>
    </Paper>
  );
}
