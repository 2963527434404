import {
  Box,
  Checkbox,
  Grid,
  // Tooltip,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
// import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import { useSelector } from "react-redux";
import { useState } from "react";
import _ from "lodash";
const FeatureText = ({
  center,
  isCoverage,
  isDeductible,
  deductible,
  coverage,
  label,
}) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  return (
    <>
      {label && (
        <Typography
          component={"div"}
          sx={{
            color: "#000",
            fontSize: isMobile ? ".75rem" : ".85rem",
            fontWeight: "600",
            textAlign: center ? "center" : "left",
            paddingTop: "2px",
          }}
        >
          {label}
        </Typography>
      )}
      {isCoverage && (
        <Typography
          component={"div"}
          sx={{
            color: "#000",
            fontSize: isMobile ? ".7rem" : ".8rem",
            textAlign: center ? "center" : "left",
          }}
        >
          {coverage}
        </Typography>
      )}
      {isDeductible && (
        <Typography
          sx={{
            color: "rgba(0,0,0,0.7)",
            fontSize: isMobile ? ".7rem" : ".8rem",
            textAlign: center ? "center" : "left",
            paddingBottom: "10px",
          }}
        >
          {deductible}
        </Typography>
      )}
    </>
  );
};
const FeatureRow = ({
  feature_label = "",
  description = "",
  dataValues = [],
  onAddToSelected = () => {},
  showOnlySelected,
}) => {
  const { showDifference } = useSelector((state) => state.quote);
  const areAllValuesEqualToReferenceValue =
    _.uniq(_.map(dataValues, "compare_value")).length === 1;
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const [checked, setChecked] = useState();
  if (showOnlySelected && !checked) return <></>;
  if (showDifference && areAllValuesEqualToReferenceValue) return <></>;
  return (
    <>
      <Grid
        item
        xs={isMobile ? 4 : 3}
        mb={2}
        sx={{
          color: "#000",
          display: "flex",
          position: "relative",
        }}
      >
        <Checkbox
          sx={{
            p: 0,
            ml: isMobile ? 1 : 2,
            mr: 1,
            alignItems: "self-start",
          }}
          disableRipple
          checked={checked}
          onChange={() => {
            onAddToSelected();
            setChecked(!checked);
          }}
        />
        <Box>
          <Box display="flex">
            <FeatureText label={feature_label} />
            {/* <Tooltip title={description} arrow placement="top">
              <InfoOutlinedIcon
                sx={{
                  height: isMobile ? ".9rem" : "1rem",
                  width: isMobile ? ".9rem" : "1rem",
                  ml: isMobile ? 0 : 1,
                  mt: 0.3,
                }}
              />
            </Tooltip> */}
          </Box>
        </Box>
      </Grid>
      {(isMobile ? [0, 1] : [0, 1, 2]).map((item) => {
        return (
          <Grid item xs={isMobile ? 4 : 3} key={item}>
            {dataValues[item]?.compare_value ? (
              <FeatureText
                isCoverage={dataValues?.[item]?.compare_value}
                coverage={dataValues?.[item]?.compare_value}
              />
            ) : (
              <FeatureText isCoverage={true} coverage={"-"} center />
            )}
          </Grid>
        );
      })}
    </>
  );
};

export default FeatureRow;
