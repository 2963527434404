import { Check, Close } from "@mui/icons-material";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  Grid,
  IconButton,
  Stack,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import {
  setCompareQuote,
  removeQuoteFromCompare,
  setShowAddCompareModal,
} from "../../../modules/quote.slice";
import {
  useGetQuotes,
  useGetUserDetails,
} from "../../../services/quoteService";
import Title from "../../ProposalPage/components/FormTitle";

const AddPlanToCompare = () => {
  const { quotes, sortedKeys } = useGetQuotes();

  const dispatch = useDispatch();
  const { showAddCompareModal } = useSelector((state) => state.quote);
  const onClose = () => dispatch(setShowAddCompareModal(false));
  return (
    <>
      <Dialog
        open={showAddCompareModal}
        onClose={onClose}
        maxWidth={"md"}
        fullWidth
      >
        <Title>Add up to 3 plans to compare</Title>
        <IconButton
          onClick={onClose}
          sx={{
            position: "absolute",
            top: "10px",
            right: "10px",
          }}
        >
          <Close />
        </IconButton>
        <DialogContent>
          <Grid container spacing={2} mt={1}>
            {sortedKeys?.map((key, index) =>
              quotes[key]
                ?.sort(
                  (a, b) =>
                    a?.quoteData?.data[0]?.total_premium_amount -
                    b?.quoteData?.data[0]?.total_premium_amount
                )
                ?.map((quote, idx) => (
                  <>
                    {quote?.quoteData?.data[0]?.plan_name ? (
                      <AddCompareQuote quote={quote} />
                    ) : null}
                  </>
                ))
            )}
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button
            sx={{
              fontSize: "14px",
              textTransform: "none",
              borderRadius: "5px",
              mr: 2,
            }}
            onClick={onClose}
          >
            Compare
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};
const AddCompareQuote = ({ quote }) => {
  const { compareQuote } = useSelector((state) => state.quote);
  const isSelected = compareQuote.some(
    (item) => item.data[0]?.plan_name === quote?.quoteData?.data[0]?.plan_name
  );
  const dispatch = useDispatch();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const { userDetails } = useGetUserDetails();
  const handleCompare = () => {
    if (compareQuote.length < 3 && !isSelected) {
      dispatch(setCompareQuote(quote.quoteData));
    } else {
      dispatch(removeQuoteFromCompare(quote.quoteData));
    }
  };
  if (isMobile) {
    return (
      <>
        <Grid item xs={6}>
          <Box
            display={"flex"}
            width="100%"
            height="100%"
            alignItems={"center"}
            position={"relative"}
            sx={{
              boxShadow: "rgb(226 227 237) 0px 2px 15px 5px",
              border: isSelected ? "1px solid" : "none",
              borderColor: "primary.main",
              borderRadius: "5px",
              cursor: "pointer",
            }}
            onClick={handleCompare}
          >
            {isSelected && (
              <Box
                position="absolute"
                sx={{
                  borderRadius: "50%",
                  top: "-10px",
                  right: "-10px",
                  border: "1px solid",
                  borderColor: "primary.main",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  backgroundColor: "white",
                }}
              >
                <Check />
              </Box>
            )}
            <Box display={"flex"} width="100%" flexDirection={"column"} p={2}>
              <Stack direction={"row"} alignItems={"center"} spacing={2}>
                <Box
                  sx={{
                    width: "40%",
                    height: "35px",
                  }}
                >
                  {" "}
                  <img
                    src={quote?.quoteData?.ic_data[0]?.logo}
                    alt=""
                    width="30px"
                    height="auto"
                  />
                </Box>
                <Box
                  sx={{
                    width: "60%",
                    fontSize: "10px",
                  }}
                >
                  ₹{" "}
                  {quote?.quoteData?.data[0]?.total_premium_amount?.toLocaleString(
                    "en-IN"
                  )}
                </Box>
              </Stack>
              <Typography variant="h3" fontSize="9px" fontWeight={500}>
                {quote?.quoteData?.data[0]?.plan_name}
              </Typography>
            </Box>
          </Box>
        </Grid>
      </>
    );
  }
  return (
    <>
      <Grid item xs={6} md={4}>
        <Box
          display={"flex"}
          width="100%"
          height="100%"
          alignItems={"center"}
          position={"relative"}
          sx={{
            boxShadow: "rgb(226 227 237) 0px 2px 15px 5px",
            border: isSelected ? "1px solid" : "none",
            borderColor: "primary.main",
            borderRadius: "5px",
            cursor: "pointer",
          }}
          onClick={handleCompare}
        >
          {isSelected && (
            <Box
              position="absolute"
              sx={{
                borderRadius: "50%",
                top: "-10px",
                right: "-10px",
                border: "1px solid",
                borderColor: "primary.main",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                backgroundColor: "white",
              }}
            >
              <Check />
            </Box>
          )}
          <Box display={"flex"} width="70%" flexDirection={"column"} p={2}>
            <Box
              sx={{
                width: "80px",
                height: "50px",
              }}
            >
              {" "}
              <img
                src={
                  userDetails?.company_details[
                    quote.quoteData.ic_data[0].company_alias
                  ]?.logo
                }
                alt=""
                width="auto"
                height="50px"
              />
            </Box>
            <Typography variant="h3" fontSize="12px" fontWeight={500}>
              {quote?.quoteData?.data[0]?.plan_name}
            </Typography>
          </Box>
          <Box>
            ₹{" "}
            {quote?.quoteData?.data[0]?.total_premium_amount?.toLocaleString(
              "en-IN"
            )}
          </Box>
        </Box>
      </Grid>
    </>
  );
};

export default AddPlanToCompare;
