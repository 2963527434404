import {
  Box,
  Button,
  Card,
  Grid,
  Paper,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { Form, Formik } from "formik";
import RadioB from "./../components/RadioB";
import TextInput from "../../../components/InputComponents/TextInput";
import Title from "../components/FormTitle";
import DatePicker from "../../../components/InputComponents/DatePicker";
import * as yup from "yup";
import { useStoreProposalMutation } from "../../../services/proposalService";
import Select from "../../../components/InputComponents/Select";
import { useDispatch, useSelector } from "react-redux";
import { setProposalData } from "../../../modules/proposal.slice";
import FormikSideEffect from "../../../hoc/FormikSideEffect";
import {
  allowFullNameWithLastNameAsPeriod,
  allowOnlyAlphabetsWithOneSpace,
  allowOnlyNumbers,
  capitalize,
  getMaxDateByAge,
  getMinDateByAge,
  lowerCase,
  upperCase,
} from "../../../utils/input";
import moment from "moment";
import { useGetUserDetails } from "../../../services/quoteService";
import Summary from "../components/Summary";
import SummaryData from "../components/SummaryData";
import { genderWithFullCode, salutations } from "../../../utils/options";
import { Fragment } from "react";
import { careSalutations } from "../constants/care.constant";
import ButtonLoader from "../../../components/ButtonLoader/ButtonLoader";
import { emailRegex } from "../../../utils/validation";

const members = [{ value: "self", label: "Self" }];
const ProposerDetails = ({ index, ckycIndex }) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const { storeProposal, isLoading } = useStoreProposalMutation({ index });
  const { proposalData, active } = useSelector((state) => state.proposal);
  const proposerFormData = proposalData?.[`proposar`];
  const dispatch = useDispatch();
  const { userDetails, isDetailsLoading } = useGetUserDetails();
  const selected_plan = userDetails?.selected_plan;
  const isPanMandatory = selected_plan?.sum_insured >= 50000;
  const proposer_age = userDetails?.proposer_age;
  const isProposerAdult =
    (proposer_age >= 18 && [1, 2]?.includes(+selected_plan?.ic_id)) ||
    [4, 6, 7]?.includes(+selected_plan?.ic_id);
  const Name = userDetails?.proposer_name;
  const space = Name?.indexOf(" ");
  const firstName = Name?.substring(0, space);
  const lastName = Name?.substring(space + 1);
  const initialValues = proposerFormData
    ? proposerFormData
    : {
        salutation_id: "1",
        first_name: isProposerAdult ? firstName : "",
        last_name: isProposerAdult ? lastName : "",
        dob: "",
        email: isProposerAdult ? userDetails?.proposer_email : "",
        mobile_no: isProposerAdult ? userDetails?.proposer_mobile_no : "",
        gender: "Male",
        passport_number: "",
        nationality: "Indian",
        pan_no: "",
      };
  const validationSchema = yup.object().shape({
    salutation_id: yup.string().required("Salutation is Required"),
    first_name: yup
      .string()
      .required("First Name is Required")
      .matches(/^([A-Za-z\s])+$/, "Must contain only alphabets")
      .min(1, "Minimum 1 chars required")
      .max(50, "Must be less than 50 chars"),
    last_name: yup
      .string()
      .required("If last name is not available enter '.' (dot).")
      .min(1, "Minimum 1 chars required")
      .max(50, "Must be less than 50 chars")
      .test(
        "last_name",
        "If last name not available enter '.' (dot).",
        (value) => /^(?:[A-Za-z]+(?: [A-Za-z]+)*|\.)(?: \.)?$/.test(value)
      ),
    dob: yup
      .string()
      .required("Date of Birth is Required")
      .test("dob", "Please enter a valid date of birth", (value) => {
        if (moment(value, "YYYY-MM-DD").isValid()) return true;
      })
      .test("dob", "Please enter a valid date of birth", (value) => {
        if (
          isProposerAdult
            ? moment(value, "YYYY-MM-DD").isBetween(
                getMinDateByAge(proposer_age),
                getMaxDateByAge(proposer_age)
              )
            : moment(value, "YYYY-MM-DD").isBefore(getMaxDateByAge(18))
        ) {
          return true;
        }
      }),
    email: yup
      .string()
      .required("Email Id is Required")
      .matches(emailRegex, "Please enter valid email id")
      .email("Please enter a valid email"),
    mobile_no: yup
      .string()
      .required("Mobile Number is Required")
      .matches(/^[6-9]\d{9}$/, "Please enter a valid mobile number"),
    passport_number: yup
      .string()
      .required("Passport Number is Required")
      .matches(/^[A-Z]{1}[0-9]{7}$/, "Please enter a valid passport number"),
    nationality: yup.string().required("Nationality is required"),
    pan_no: isPanMandatory
      ? yup
          .string()
          .required("Pan Number is Required")
          .matches(
            /^[A-Z]{3}[P]{1}[A-Z]{1}[0-9]{4}[A-Z]{1}$|^$/,
            "Please enter a valid pan number"
          )
          .min(10, "Please enter a valid pan number")
      : yup
          .string()
          .matches(
            /^[A-Z]{3}[P]{1}[A-Z]{1}[0-9]{4}[A-Z]{1}$|^$/,
            "Please enter a valid pan number"
          )
          .min(10, "Please enter a valid pan number"),
  });
  if (index < active) {
    return (
      <>
        <Summary title="Proposer Details" index={index}>
          <SummaryData
            label="Salutation*"
            value={
              salutations.find(
                (item) => item.code === proposerFormData?.salutation_id
              )?.display_name
            }
          />
          <SummaryData
            label="First Name*"
            value={proposerFormData?.first_name}
          />
          <SummaryData label="Last Name*" value={proposerFormData?.last_name} />
          <SummaryData label="Gender*" value={proposerFormData?.gender} />
          <SummaryData
            label="Date of Birth*"
            value={moment(proposerFormData?.dob).format("DD/MM/YYYY")}
          />
          <SummaryData label="Email*" value={proposerFormData?.email} />
          <SummaryData
            label="Mobile Number*"
            value={proposerFormData?.mobile_no}
          />
          <SummaryData
            label="Passport Number*"
            value={proposerFormData?.passport_number}
          />
          <SummaryData
            label="Nationality*"
            value={proposerFormData?.nationality}
          />
          <SummaryData label="Pan No*" value={proposerFormData?.pan_no} />
        </Summary>
      </>
    );
  }
  if (isDetailsLoading || !userDetails) return <></>;
  return (
    <>
      <Paper sx={{ m: 2, backgroundColor: "#F0F4F5" }}>
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={(values) => {
            dispatch(setProposalData({ proposar: values }));
            storeProposal({
              step: 1,
              additional_data: { proposar: values },
              ...values,
              is_proposer: 1,
            });
          }}
          enableReinitialize={true}
        >
          {({ values, setFieldValue }) => (
            <Form noValidate>
              <Card>
                <Title>Proposer Details </Title>
                {members?.map((member) => (
                  <Fragment key={JSON.stringify(member)}>
                    <Box px={3} py={2} key={member}>
                      {members?.length > 1 && (
                        <Typography variant="h4" gutterBottom>
                          {member?.label}
                        </Typography>
                      )}
                      <Grid alignItems="flex-start" container spacing={2}>
                        <Grid item xs={12} md={4}>
                          <FormikSideEffect
                            effect={() => {
                              if (values.salutation_id === "1") {
                                setFieldValue(`gender`, "Male");
                              } else if (
                                values.salutation_id === "2" ||
                                values.salutation_id === "3"
                              ) {
                                setFieldValue(`gender`, "Female");
                              }
                            }}
                            dependencies={[values?.salutation_id]}
                          >
                            <Select
                              fixedLabel={false}
                              name="salutation_id"
                              label="Salutation*"
                              options={
                                +selected_plan?.ic_id === 1
                                  ? careSalutations
                                  : salutations
                              }
                              selectOnly={true}
                            />
                          </FormikSideEffect>
                        </Grid>
                        <Grid item xs={12} md={4}>
                          <TextInput
                            name={`first_name`}
                            label="First Name"
                            onInput={(e) => {
                              capitalize(e);
                              allowOnlyAlphabetsWithOneSpace(e);
                            }}
                            inputProps={{ minLength: 1, maxLength: 50 }}
                            required
                          />
                        </Grid>
                        <Grid item xs={12} md={4}>
                          <TextInput
                            name={`last_name`}
                            label="Last Name"
                            onInput={allowFullNameWithLastNameAsPeriod}
                            inputProps={{ minLength: 1, maxLength: 50 }}
                            required
                          />
                        </Grid>
                        <Grid item xs={12} md={4}>
                          <RadioB
                            mt={2}
                            value={
                              values.salutation_id === "1" ? "Male" : "Female"
                            }
                            options={genderWithFullCode}
                            name={`gender`}
                          />
                        </Grid>
                        <Grid item xs={12} md={4}>
                          <DatePicker
                            name={`dob`}
                            label="Date of Birth"
                            minDate={
                              isProposerAdult
                                ? getMinDateByAge(proposer_age)
                                : false
                            }
                            maxDate={
                              isProposerAdult
                                ? getMaxDateByAge(proposer_age)
                                : getMaxDateByAge(18)
                            }
                            views={["year", "month", "day"]}
                            openTo="year"
                            selectOnly={true}
                            placeholder="Date Of Birth"
                            required
                          />
                        </Grid>
                        <Grid item xs={12} md={4}>
                          <TextInput
                            name={`mobile_no`}
                            label="Mobile Number"
                            inputProps={{ maxLength: 10, inputMode: "numeric" }}
                            onInput={allowOnlyNumbers}
                            required
                          />
                        </Grid>
                        <Grid item xs={12} md={4}>
                          <TextInput
                            name={`email`}
                            label="Email"
                            required
                            onInput={lowerCase}
                          />
                        </Grid>
                        <Grid item xs={12} md={4}>
                          <TextInput
                            name={`passport_number`}
                            label="Passport Number"
                            inputProps={{ maxLength: 8 }}
                            onInput={upperCase}
                            helperText="e.g J1234567"
                            required
                          />
                        </Grid>
                        <Grid item xs={12} md={4}>
                          <TextInput
                            name={`nationality`}
                            label="Nationality"
                            inputProps={{ readOnly: true }}
                            required
                          />
                        </Grid>
                        <Grid item xs={12} md={4}>
                          <TextInput
                            name={`pan_no`}
                            inputProps={{ maxLength: 10 }}
                            onInput={upperCase}
                            label="Pan Number"
                            helperText="e.g AAAPL1234C"
                            required={isPanMandatory}
                          />
                        </Grid>
                      </Grid>
                    </Box>
                  </Fragment>
                ))}
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "flex-end",
                    alignItems: "center",
                    p: 2,
                  }}
                >
                  <Button
                    fullWidth={isMobile ? true : false}
                    sx={{
                      textTransform: "none",
                      fontSize: isMobile ? "13px" : "16px",
                      p: 2,
                      pl: 4,
                      pr: 4,
                      borderRadius: 1,
                    }}
                    variant="contained"
                    color="secondary"
                    type="submit"
                  >
                    {isLoading ? (
                      <ButtonLoader />
                    ) : ckycIndex ? (
                      "Proceed to CKYC Details"
                    ) : (
                      "Proceed to Traveler"
                    )}
                  </Button>
                </Box>
              </Card>
            </Form>
          )}
        </Formik>
      </Paper>
    </>
  );
};

export default ProposerDetails;
