import html2canvas from "html2canvas";

export const getAgeInRange = (min, max) => {
  const age = [];
  for (let i = min; i <= max; i++) {
    age.push({
      code: i,
      display_name: `${i === 1 ? `${i} year` : `${i} years`}`,
    });
  }
  return age;
};

export const printImageById = async (id) => {
  const input = document.getElementById(id);
  const canvas = await html2canvas(input, {
    useCORS: true,
    allowTaint: false,
    scrollX: 0,
    scrollY: -window.scrollY,
  });
  const imgData = canvas.toDataURL("image/jpeg");
  return imgData;
};
