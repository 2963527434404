import { FilterAltOutlined, PhoneOutlined } from "@mui/icons-material";
import {
  Card,
  Dialog,
  DialogContent,
  Grid,
  Stack,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import React, { useState } from "react";
import Modal from "../../../components/Modal";
import SortBy from "../../../components/Popups/SortBy";
import TalkToExpert from "../../../components/Popups/TalkToExpert";
import MobileShortListB from "../../ShortlistedQuotePage/MobileShortListB";
import SendQuoteB from "./SendQuoteB";

export default function MobileFooter() {
  const [openTalkToExpert, setOpenTalkToExpert] = useState(false);
  const theme = useTheme();
  const isTablet = useMediaQuery(theme.breakpoints.between("sm", "md"));
  const [openSort, setOpenSort] = useState(false);
  const [open, setOpen] = useState(false);
  const handleTalkToExpert = () => {
    setOpenTalkToExpert(true);
  };
  const handleCloseTte = () => {
    setOpenTalkToExpert(false);
  };
  const handleClickOpenSort = () => {
    setOpenSort(true);
  };
  const handleCloseSort = () => {
    setOpenSort(false);
  };
  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  return (
    <>
      <Card sx={{ p: 0 }}>
        <Grid container justifyContent="center">
          <Grid py={1} px={2} item xs={3}>
            <Stack
              onClick={handleClickOpenSort}
              spacing={1}
              alignItems={"center"}
            >
              <FilterAltOutlined />
              <span
                style={{
                  fontSize: isTablet ? "16px" : "12px",
                  fontWeight: "500",
                  color: "#000000",
                  whiteSpace: "nowrap",
                }}
              >
                Sort By
              </span>
            </Stack>
          </Grid>
          <Grid py={1} px={2} item xs={3}>
            <MobileShortListB handleClickOpen={handleClickOpen} />
          </Grid>
          <Grid py={2} px={2} item xs={3}>
            <Stack spacing={1} alignItems={"center"}>
              <SendQuoteB />
            </Stack>
          </Grid>
          <Grid py={1} px={1} item xs={3}>
            <Stack
              onClick={handleTalkToExpert}
              spacing={1}
              alignItems={"center"}
            >
              <PhoneOutlined />
              <span
                style={{
                  fontSize: isTablet ? "16px" : "12px",
                  fontWeight: "500",
                  color: "#000000",
                  whiteSpace: "nowrap",
                }}
              >
                Contact Us
              </span>
            </Stack>
          </Grid>
        </Grid>
      </Card>
      <Modal
        open={openTalkToExpert}
        handleClose={handleCloseTte}
        maxWidth={isTablet ? "420px" : "342px"}
      >
        <TalkToExpert />
      </Modal>
      <Modal
        open={openSort}
        handleClose={handleCloseSort}
        maxWidth={isTablet ? "455px" : "342px"}
      >
        <SortBy handleClose={handleCloseSort} />
      </Modal>
      <Dialog
        sx={{
          "& .MuiDialog-paper": {
            borderRadius: "10px",
          },
        }}
        open={open}
        onClose={handleClose}
      >
        <DialogContent
          sx={{
            padding: isTablet ? "70px 91px" : "33px 45px",
          }}
        >
          <Typography fontSize={"16px"} variant="h6" align="center">
            No Plans Shortlisted Yet
          </Typography>
        </DialogContent>
      </Dialog>
    </>
  );
}
