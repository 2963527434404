import {
  Box,
  Paper,
  Stack,
  Typography,
  Button,
  Accordion,
  AccordionSummary,
  AccordionDetails,
} from "@mui/material";
import React from "react";
import LeftC from "./components/LeftC";
import pdf from "../../assets/images/pdf.jpg";
import { useGetUserDetails } from "../../services/quoteService";
import { useSelector } from "react-redux";
import { CheckBoxOutlined } from "@mui/icons-material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

export default function LeftCard() {
  const { userDetails } = useGetUserDetails();
  const selected_plan = userDetails?.selected_plan;
  const features = selected_plan?.plan_details
    ? selected_plan?.plan_details[0]?.plan_feature
    : [];
  const [...otherFeatures] = features;
  const featuresToShow =
    otherFeatures
      .filter((item) => item.feature_values)
      .flat()
      ?.filter((item) => item.feature_section_description !== "Plan Details") ||
    [];
  const { know_more_data } = useSelector((state) => state.quote);
  const { ic_data } = know_more_data;
  return (
    <Paper sx={{ maxWidth: "327px", m: 3, backgroundColor: "#F0F4F5" }}>
      <LeftC />
      {featuresToShow?.length ? (
        <Box sx={{ m: 1, mt: 2 }}>
          <Accordion sx={{ p: 0.1 }}>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon sx={{ color: "#0a0938" }} />}
            >
              <Typography
                sx={{
                  fontSize: ".8rem",
                  fontWeight: "bold",
                  color: "#0a0938",
                }}
              >
                Coverage Details
              </Typography>
            </AccordionSummary>
            <AccordionDetails sx={{ py: 1, pt: 0 }}>
              {featuresToShow.map((feature) => (
                <Box sx={{ py: 0.75 }} key={feature.feature_key}>
                  <Subhead title={feature.feature_key} />
                </Box>
              ))}
            </AccordionDetails>
          </Accordion>
        </Box>
      ) : (
        <></>
      )}
      <Box sx={{ m: 1 }}>
        <TitleInfo
          target={"_blank"}
          download="Policy Wording"
          href={ic_data && ic_data[0].policy_wording}
        >
          Download Policy Wording
        </TitleInfo>
        <TitleInfo
          target={"_blank"}
          download="Brochure"
          href={ic_data && ic_data[0]?.travel_broucher}
        >
          Download Brochure
        </TitleInfo>
      </Box>
    </Paper>
  );
}

const Subhead = ({ title }) => {
  return (
    <Stack alignItems={"center"} spacing={2} direction="row">
      <CheckBoxOutlined color="primary" />
      <Typography
        variant="body1"
        sx={{ fontSize: "12.5px", fontWeight: "bold", color: "#000000" }}
      >
        {title}
      </Typography>
    </Stack>
  );
};

const TitleInfo = ({ download, target, children, href }) => {
  return (
    <Button
      variant={"text"}
      target={target}
      download={download}
      href={href}
      fullWidth
      sx={{
        mb: 1.25,
        p: 1,
        pl: 2,
        borderRadius: 1,
        boxShadow: 2,
        background: "#fff",
      }}
    >
      <Stack alignItems={"center"} direction={"row"} spacing={2}>
        <Typography
          sx={{
            ml: 2,
            fontSize: "12px",
            fontWeight: "bold",
            color: "#0a0938",
          }}
        >
          {children}
        </Typography>
        <Box>
          <img src={pdf} alt="pdf" style={{ width: "31px", height: "31px" }} />
        </Box>
      </Stack>
    </Button>
  );
};
