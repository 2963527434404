import { Avatar, Card, CardContent } from "@mui/material";
import styled from "styled-components";

const HeaderProfileCard = ({ sellerData, show }) => {
  return (
    <>
      {show ? (
        <Card
          sx={{
            display: "flex",
            width: "220px",
            height: "40px",
            position: "absolute",
            top: "50px",
            right: "0",
            zIndex: "10000",
          }}
        >
          <CardContent
            sx={{
              width: "75%",
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              padding: "0 10px",
            }}
          >
            <div style={{ fontSize: ".8rem" }}>{sellerData?.seller_name}</div>
            <div style={{ fontSize: ".7rem", color: "#999" }}>
              {sellerData?.seller_type}
              {sellerData?.seller_type && sellerData?.seller_id && " : "}
              {sellerData?.seller_id}
            </div>
          </CardContent>
          <Avatar
            sx={{
              height: "100%",
              width: "25%",
              fontSize: "1.2rem",
              background: "var(--primary-color)",
            }}
            variant="square"
          >
            {sellerData?.seller_name &&
              sellerData?.seller_name[0]?.toUpperCase()}
          </Avatar>
        </Card>
      ) : (
        <></>
      )}
    </>
  );
};

export default HeaderProfileCard;

export const HeaderProfileCardStyled = styled.div`
  background-color: #efefef;
  display: flex;
  align-items: center;
  justify-content: center;
  img {
    margin: 5px;
  }
`;
export const HeaderProfileDescriptionStyled = styled.div`
  background-color: #efefef;
`;
