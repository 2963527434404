import {
  Button,
  Stack,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import React from "react";
import arrow from "../../../../../assets/images/arrow-ios-back.png";

export default function KnowMoreB({ onClick, recommended }) {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));
  const isTablet = useMediaQuery(theme.breakpoints.between("sm", "md"));
  return (
    <Button
      fullWidth={isMobile ? true : false}
      onClick={onClick}
      sx={{
        backgroundColor: "var(--primary-light)",
        borderRadius: 3,
        p: 0.8,
        pl: isMobile ? 0 : isTablet ? 0.5 : 1.5,
        pr: isMobile ? 0 : isTablet ? 0.5 : 1.5,
        border: "none",
        maxWidth: isTablet ? "100px" : isMobile ? "86px" : "60%",
        maxHeight: isMobile ? "22px" : "20px",
        minWidth: isTablet ? "118px" : "",
        textTransform: "none",
        boxShadow: "none",
        ":hover": {
          backgroundColor: "var(--primary-light)",
          boxShadow: "none",
        },
      }}
      variant="contained"
    >
      <Stack alignItems="center" spacing={1} direction="row">
        <Typography
          sx={{
            mt: isMobile ? 0 : isTablet ? 1 : 0.1,
            color: "var(--primary-color)",
            fontSize: isTablet ? "12px" : isMobile ? "8px" : "10px",
            fontWeight: isTablet ? "500" : "400",
          }}
          variant="body1"
        >
          Know More
        </Typography>
        <img
          src={arrow}
          width={isTablet ? "4px" : isMobile ? "3px" : "4px"}
          height={isTablet ? "10px" : isMobile ? "6px" : "8px"}
          alt=""
        />
      </Stack>
    </Button>
  );
}
