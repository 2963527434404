import * as React from "react";
import { styled } from "@mui/material/styles";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import { Box } from "@mui/material";

const BootstrapDialog = styled(Dialog)(({ theme, maxWidth }) => ({
  "& .MuiDialog-container": {
    "& .MuiPaper-root": {
      maxWidth: maxWidth,
      backgroundSize: "340px 567px",
    },
  },
}));

const BootstrapDialogTitle = (props) => {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 0 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            padding: 0,
            fontSize: "1.25rem",
            color: (theme) => theme.palette.grey[900],
          }}
        >
          <CloseIcon fontSize="1.25rem" />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
};

export default function Modal({
  children,
  title,
  open,
  handleClose,
  maxWidth,
}) {
  return (
    <Box sx={{ p: 0 }}>
      <BootstrapDialog
        maxWidth={maxWidth}
        onClose={handleClose}
        open={open}
        fullWidth
      >
        <BootstrapDialogTitle
          id="customized-dialog-title"
          onClose={handleClose}
        >
          {title}
        </BootstrapDialogTitle>
        <DialogContent sx={{ p: 0 }} dividers={false}>
          {children}
        </DialogContent>
      </BootstrapDialog>
    </Box>
  );
}
