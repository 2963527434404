/* eslint-disable eqeqeq */
import {
  Button,
  Card,
  Grid,
  useTheme,
  Typography,
  useMediaQuery,
  Box,
  Paper,
} from "@mui/material";
import { Form, Formik } from "formik";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import Select from "../../../components/InputComponents/Select";
import TextInput from "../../../components/InputComponents/TextInput";
import { setProposalData } from "../../../modules/proposal.slice";
import {
  useCkycSubmitMutation,
  useStoreProposalMutation,
} from "../../../services/proposalService";
import { allowOnlyNumbers, upperCase } from "../../../utils/input";
import Title from "../components/FormTitle";
import Summary from "../components/Summary";
const docMap = [
  { code: "1", display_name: "CKYC NUMBER" },
  { code: "2", display_name: "PAN CARD" },
  { code: "3", display_name: "Document Upload" },
];
const poi_doc = [
  { code: "1", display_name: "CUSTOMER_PHOTO" },
  { code: "2", display_name: "CKYC" },
  { code: "3", display_name: "AADHAAR" },
  { code: "4", display_name: "DRIVING_LICENSE" },
  { code: "5", display_name: "VOTER_ID" },
  { code: "6", display_name: "PASSPORT" },
  { code: "7", display_name: "PAN" },
  { code: "8", display_name: "GST" },
  { code: "9", display_name: "COI" },
  { code: "10", display_name: "RENT_AGREEMENT" },
  { code: "11", display_name: "JOB_CARD" },
  { code: "12", display_name: "NOTARTY_PROOF" },
  { code: "13 ", display_name: "UTILITY_BILL" },
  { code: "14", display_name: "RATION_CARD" },
  { code: "15", display_name: "BANK_PASSBOOK" },
  { code: "16", display_name: "POSTOFFICE_PASSBOOK" },
  { code: "17", display_name: "ARMS_LICENSE" },
  { code: "18", display_name: "ADDRESS_CARD" },
  { code: "19", display_name: "KISSAN_PASSBOOK" },
  { code: "20", display_name: "CGHS_ECHS_CARD" },
  { code: "21", display_name: "PHOTO_CREDIT_CARD" },
  { code: "22", display_name: "IT_PROOF" },
  { code: "23", display_name: "PHOTO_PENSIONER_CARD" },
  { code: "24", display_name: "BANK_STATEMENT" },
  { code: "25", display_name: "AADHAAR_IMAGE" },
  { code: "26", display_name: "DRIVING_LICENSE_IMAGE" },
  { code: "27", display_name: "VOTER_ID_IMAGE" },
  { code: "28", display_name: "PASSPORT_IMAGE" },
];
const poa_doc = [
  { code: "1", display_name: "CUSTOMER PHOTO" },
  { code: "2", display_name: "CKYC" },
  { code: "3", display_name: "AADHAAR" },
  { code: "4", display_name: "DRIVING LICENSE" },
  { code: "5", display_name: "VOTER ID" },
  { code: "6", display_name: "PASSPORT" },
  { code: "7", display_name: "PAN" },
  { code: "8", display_name: "GST" },
  { code: "9", display_name: "COI" },
  { code: "10", display_name: "RENT AGREEMENT" },
  { code: "11", display_name: "JOB CARD" },
  { code: "12", display_name: "NOTARTY PROOF" },
  { code: "13 ", display_name: "UTILITY BILL" },
  { code: "14", display_name: "RATION CARD" },
  { code: "15", display_name: "BANK PASSBOOK" },
  { code: "16", display_name: "POSTOFFICE PASSBOOK" },
  { code: "17", display_name: "ARMS LICENSE" },
  { code: "18", display_name: "ADDRESS CARD" },
  { code: "19", display_name: "KISSAN PASSBOOK" },
  { code: "20", display_name: "CGHS ECHS CARD" },
  { code: "21", display_name: "PHOTO CREDIT CARD" },
  { code: "22", display_name: "IT PROOF" },
  { code: "23", display_name: "PHOTO PENSIONER CARD" },
  { code: "24", display_name: "BANK STATEMENT" },
  { code: "25", display_name: "AADHAAR IMAGE" },
  { code: "26", display_name: "DRIVING LICENSE IMAGE" },
  { code: "27", display_name: "VOTER ID IMAGE" },
  { code: "28", display_name: "PASSPORT IMAGE" },
];

const CkycDigit = ({ index }) => {
  // --------------------------------doc upload base64 function starts-----------------------------//
  const convertToBase64 = (file) => {
    return new Promise((resolve, reject) => {
      const fileReader = new FileReader();
      fileReader.readAsDataURL(file);
      fileReader.onload = () => {
        resolve(fileReader.result);
      };
      fileReader.onerror = (error) => {
        reject(error);
      };
    });
  };

  const handleProfile = async (e, setFieldValue) => {
    const file = e.target.files[0];
    if (file?.size / 1024 / 1024 <= 10) {
      const base64 = await convertToBase64(file);
      setFieldValue("image", base64);
    } else {
      toast.error(
        <div style={{ fontSize: ".8rem" }}>
          Image size must be of 10MB or less
        </div>
      );
    }
  };

  const handleProfile1 = async (e, setFieldValue) => {
    const file = e.target.files[0];
    if (file?.size / 1024 / 1024 <= 10) {
      const base64 = await convertToBase64(file);
      setFieldValue("files", base64);
    } else {
      toast.error(
        <div style={{ fontSize: ".8rem" }}>
          Image size must be of 10MB or less
        </div>
      );
    }
  };

  // --------------------------------doc upload base64 function ends-----------------------------//
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const { proposalData, cKycFailCount, cKycStatus, active } = useSelector(
    (state) => state.proposal
  );
  const pan_no = proposalData?.proposar?.pan_no;
  const dispatch = useDispatch();
  const { storeProposal } = useStoreProposalMutation({ index });
  const { cKycValidate } = useCkycSubmitMutation({ index });
  const initialData = {
    docType: "",
    kycNumber: "",
    pan_no: pan_no,
    proofofidentity: "",
    poidentityDocUpload: "",
    proofofaddress: "",
    poaddressDocUpload: "",
  };
  if (index < active) {
    return (
      <>
        <Summary title="CKYC Details" index={index}></Summary>
      </>
    );
  }
  if (index > active) {
    return (
      <Paper
        sx={{
          m: 2,
          backgroundColor: "#Fff",
        }}
      >
        <Typography fontSize={"22px"} p={2}>
          CKYC Details
        </Typography>
      </Paper>
    );
  }
  return (
    <>
      <Paper sx={{ m: 2, backgroundColor: "#F0F4F5" }}>
        <Formik
          initialValues={initialData}
          onSubmit={(values) => {
            dispatch(setProposalData({ ckyc_data: values }));
            storeProposal({
              step: 10,
              additional_data: { ...proposalData, ckyc_data: { ...values } },
              ...values,
              is_proposer: 1,
            });
          }}
        >
          {({ errors, setFieldError, setFieldValue, values }) => (
            <div
              style={{
                backgroundColor: "#f0f4f5",
                borderRadius: "4px",
                boxShadow:
                  " 0px 2px 1px -1px rgb(0 0 0 / 20%), 0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%)",
                transition:
                  " box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
              }}
            >
              <Form>
                <Card>
                  <Title>CKYC Details</Title>
                  <div
                    className="main"
                    style={{
                      padding: "10px",
                    }}
                  >
                    {cKycFailCount == 0 && (
                      <>
                        {" "}
                        <Grid
                          item
                          xs={12}
                          md={12}
                          sm={12}
                          sx={{ padding: "16px" }}
                        >
                          {/* <h6>Select Document</h6> */}
                          <Grid item xs={12} md={5} sm={12}>
                            <Select
                              name={`docType`}
                              label="Select Document"
                              placeholder="Select"
                              options={docMap}
                            />
                          </Grid>
                        </Grid>
                        {values?.docType === "1" ? (
                          <>
                            <Grid
                              item
                              xs={12}
                              md={12}
                              sm={12}
                              sx={{
                                padding: "16px",
                                //   width: "70%",
                              }}
                            >
                              <Grid item xs={12} md={5} sm={12}>
                                <TextInput
                                  name={`kycNumber`}
                                  label="CKYC Number"
                                  inputProps={{
                                    inputMode: "numeric",
                                    maxLength: "12",
                                  }}
                                  onInput={allowOnlyNumbers}
                                  //  error={errors?.kycNumber}
                                />
                              </Grid>
                              {/* <Grid
                                item
                                xs={12}
                                md={5}
                                style={{
                                  paddingTop: "16px",
                                }}
                              >
                                <Button
                                  style={{
                                    borderRadius: "2px",
                                  }}
                                  variant="contained"
                                  color="secondary"
                                  onClick={(evt) => {
                                    evt.preventDefault();
                                    if (values.kycNumber) {
                                      cKycValidate({
                                        // ...values,
                                        kycNumber: values?.kycNumber,
                                      });
                                    } else {
                                      setFieldError(
                                        "kycNumber",
                                        "This field is required"
                                      );
                                    }
                                  }}
                                >
                                  Validate CKYC{" "}
                                </Button>
                              </Grid> */}
                            </Grid>
                            <Box
                              sx={{
                                display: "flex",
                                // justifyContent: "flex-end",
                                alignItems: "center",
                                p: 2,
                              }}
                            >
                              <Button
                                fullWidth={isMobile ? true : false}
                                sx={{
                                  textTransform: "none",
                                  fontSize: isMobile ? "13px" : "16px",
                                  p: 2,
                                  pl: 4,
                                  pr: 4,
                                }}
                                variant="contained"
                                color="secondary"
                                onClick={(evt) => {
                                  evt.preventDefault();
                                  if (values.kycNumber) {
                                    cKycValidate({
                                      // ...values,
                                      kycNumber: values?.kycNumber,
                                    });
                                  } else {
                                    setFieldError(
                                      "kycNumber",
                                      "This field is required"
                                    );
                                  }
                                }}
                              >
                                Validate CKYC Number
                              </Button>
                            </Box>
                          </>
                        ) : values.docType === "2" ? (
                          <>
                            <Grid
                              item
                              xs={12}
                              md={12}
                              sm={12}
                              sx={{
                                padding: "16px",
                                //   width: "70%",
                              }}
                            >
                              <Grid item xs={12} md={5} sm={12}>
                                <TextInput
                                  name={`pan_no`}
                                  label="PAN No."
                                  values={pan_no}
                                  inputProps={{
                                    maxLength: 10,
                                    readOnly: true,
                                  }}
                                  onInput={upperCase}
                                />
                              </Grid>
                              {/* <Grid
                                item
                                xs={12}
                                md={5}
                                style={{
                                  paddingTop: "16px",
                                }}
                              >
                                <Button
                                  style={{
                                    borderRadius: "2px",
                                  }}
                                  variant="contained"
                                  color="secondary"
                                  onClick={(evt) => {
                                    evt.preventDefault();
                                    if (values.pan_no) {
                                      cKycValidate({
                                        // ...values,
                                        pan_no: values?.pan_no,
                                      });
                                    } else {
                                      setFieldError(
                                        "pan_no",
                                        "This field is required"
                                      );
                                    }
                                  }}
                                >
                                  Validate PAN{" "}
                                </Button>
                              </Grid> */}
                            </Grid>
                            <Box
                              sx={{
                                display: "flex",
                                // justifyContent: "flex-end",
                                alignItems: "center",
                                p: 2,
                              }}
                            >
                              <Button
                                fullWidth={isMobile ? true : false}
                                sx={{
                                  textTransform: "none",
                                  fontSize: isMobile ? "13px" : "16px",
                                  p: 2,
                                  pl: 4,
                                  pr: 4,
                                }}
                                variant="contained"
                                color="secondary"
                                onClick={(evt) => {
                                  evt.preventDefault();
                                  if (values.pan_no) {
                                    cKycValidate({
                                      // ...values,
                                      pan_no: values?.pan_no,
                                    });
                                  } else {
                                    setFieldError(
                                      "pan_no",
                                      "This field is required"
                                    );
                                  }
                                }}
                              >
                                Validate PAN
                              </Button>
                            </Box>
                          </>
                        ) : values.docType === "3" ? (
                          <>
                            <>
                              <Grid
                                item
                                xs={12}
                                md={12}
                                sm={12}
                                sx={{ padding: "16px" }}
                              >
                                <h6>Proof of Identity</h6>
                                <Grid
                                  item
                                  xs={12}
                                  md={5}
                                  sm={12}
                                  sx={{ paddingTop: "16px" }}
                                >
                                  <Select
                                    name={`proofofidentity`}
                                    label="Select Document"
                                    options={poi_doc}
                                  />
                                </Grid>
                                <Grid item xs={12} md={5} sm={12}>
                                  <div
                                    style={{
                                      marginTop: "20px",
                                    }}
                                  >
                                    <h6 className="mobsubhead text-danger">
                                      Please upload JPEG, PDF, PNG, TIFF file
                                      types only. Size (upto 10MB)
                                    </h6>
                                  </div>
                                  <input
                                    type="file"
                                    className="form-control"
                                    name="poidentityDocUpload"
                                    accept="image/*,.pdf"
                                    onChange={(e) =>
                                      handleProfile(e, setFieldValue)
                                    }
                                  />
                                  {!!errors.image && (
                                    <h6 style={{ fontSize: "12px" }}>
                                      {errors.image}
                                    </h6>
                                  )}
                                </Grid>
                                {/* <Grid
                                  item
                                  xs={12}
                                  md={5}
                                  style={{
                                    paddingTop: "16px",
                                  }}
                                >
                                  <Button
                                    style={{
                                      borderRadius: "2px",
                                    }}
                                    variant="contained"
                                    color="secondary"
                                    onClick={(evt) => {
                                      evt.preventDefault();

                                      if (
                                        values.proofofidentity &&
                                        values.image
                                      ) {
                                        cKycValidate({
                                          // ...values,
                                          image: values?.image,
                                          proofofidentity:
                                            values?.proofofidentity,
                                        });
                                      } else {
                                        setFieldError(
                                          "proofofidentity",
                                          "This fiels is required"
                                        );
                                        setFieldError(
                                          "image",
                                          "This field is required"
                                        );
                                      }
                                    }}
                                  >
                                    Validate Documents{" "}
                                  </Button>
                                </Grid> */}
                              </Grid>
                              <Box
                                sx={{
                                  display: "flex",
                                  // justifyContent: "flex-end",
                                  alignItems: "center",
                                  p: 2,
                                }}
                              >
                                <Button
                                  fullWidth={isMobile ? true : false}
                                  sx={{
                                    textTransform: "none",
                                    fontSize: isMobile ? "13px" : "16px",
                                    p: 2,
                                    pl: 4,
                                    pr: 4,
                                    borderRadius: 1,
                                  }}
                                  variant="contained"
                                  color="secondary"
                                  onClick={(evt) => {
                                    evt.preventDefault();

                                    if (
                                      values.proofofidentity &&
                                      values.image
                                    ) {
                                      cKycValidate({
                                        // ...values,
                                        image: values?.image,
                                        proofofidentity:
                                          values?.proofofidentity,
                                      });
                                    } else {
                                      setFieldError(
                                        "proofofidentity",
                                        "This fiels is required"
                                      );
                                      setFieldError(
                                        "image",
                                        "This field is required"
                                      );
                                    }
                                  }}
                                >
                                  Verify Document
                                </Button>
                              </Box>
                            </>
                            <>
                              <Grid
                                item
                                xs={12}
                                md={12}
                                sm={12}
                                sx={{ padding: "16px" }}
                              >
                                <h6>Proof of Address</h6>
                                <Grid item xs={12} md={5} sm={12}>
                                  <Select
                                    name={`proofofaddress`}
                                    label="Select Document"
                                    options={poa_doc}
                                  />
                                </Grid>
                                <Grid item xs={12} md={5} sm={12}>
                                  <div
                                    style={{
                                      marginTop: "20px",
                                    }}
                                  >
                                    <h6 className="mobsubhead text-danger">
                                      Please upload JPEG, PDF, PNG, TIFF file
                                      types only. Size (upto 10MB)
                                    </h6>
                                  </div>
                                  <input
                                    type="file"
                                    className="form-control"
                                    name="poaddressDocUpload"
                                    accept="image/*,.pdf"
                                    onChange={(e) =>
                                      handleProfile1(e, setFieldValue)
                                    }
                                  />
                                  {!!errors.files && (
                                    <h6 style={{ fontSize: "12px" }}>
                                      {errors.files}
                                    </h6>
                                  )}
                                </Grid>
                                {/* <Grid
                                  item
                                  xs={12}
                                  md={5}
                                  style={{
                                    paddingTop: "16px",
                                  }}
                                >
                                  <Button
                                    style={{
                                      borderRadius: "2px",
                                    }}
                                    variant="contained"
                                    color="secondary"
                                    onClick={(evt) => {
                                      evt.preventDefault();
                                      if (
                                        values.proofofaddress &&
                                        values.files
                                      ) {
                                        cKycValidate({
                                          // ...values,
                                          proofofaddress:
                                            values?.proofofaddress,
                                          files: values?.files,
                                        });
                                      } else {
                                        setFieldError(
                                          "proofofaddress",
                                          "This field is required"
                                        );
                                        setFieldError(
                                          "files",
                                          "This field is required"
                                        );
                                      }
                                    }}
                                  >
                                    Validate Documents{" "}
                                  </Button>
                                </Grid> */}
                              </Grid>
                              <Box
                                sx={{
                                  display: "flex",
                                  // justifyContent: "flex-end",
                                  alignItems: "center",
                                  p: 2,
                                }}
                              >
                                <Button
                                  fullWidth={isMobile ? true : false}
                                  sx={{
                                    textTransform: "none",
                                    fontSize: isMobile ? "13px" : "16px",
                                    p: 2,
                                    pl: 4,
                                    pr: 4,
                                    borderRadius: 1,
                                  }}
                                  variant="contained"
                                  color="secondary"
                                  onClick={(evt) => {
                                    evt.preventDefault();
                                    if (values.proofofaddress && values.files) {
                                      cKycValidate({
                                        // ...values,
                                        proofofaddress: values?.proofofaddress,
                                        files: values?.files,
                                      });
                                    } else {
                                      setFieldError(
                                        "proofofaddress",
                                        "This field is required"
                                      );
                                      setFieldError(
                                        "files",
                                        "This field is required"
                                      );
                                    }
                                  }}
                                >
                                  Verify Document
                                </Button>
                              </Box>
                            </>
                          </>
                        ) : (
                          <></>
                        )}
                      </>
                    )}

                    {cKycStatus == true && (
                      <>
                        <Box
                          sx={{
                            display: "flex",
                            justifyContent: "flex-end",
                            alignItems: "center",
                            p: 2,
                          }}
                        >
                          <Button
                            fullWidth={isMobile ? true : false}
                            sx={{
                              textTransform: "none",
                              fontSize: isMobile ? "13px" : "16px",
                              p: 2,
                              pl: 4,
                              pr: 4,
                              borderRadius: 1,
                            }}
                            variant="contained"
                            color="secondary"
                            type="submit"
                          >
                            Proceed to Traveler
                          </Button>
                        </Box>
                      </>
                    )}
                  </div>
                </Card>
              </Form>
            </div>
          )}
        </Formik>
      </Paper>
    </>
  );
};

export default CkycDigit;
