import { Avatar } from "@mui/material";
import { useState } from "react";
import styled from "styled-components";
import HeaderProfileCard from "./HeaderProfileCard";

const HeaderProfile = ({ sellerData }) => {
  const [show, setShow] = useState(false);

  return (
    <>
      {sellerData?.seller_name && (
        <HeaderProfileContainerStyled>
          <Avatar
            sx={avatarStyles}
            onMouseOver={() => setShow(true)}
            onMouseOut={() => setShow(false)}
          >
            {sellerData?.seller_name &&
              sellerData?.seller_name[0]?.toUpperCase()}
          </Avatar>
          <HeaderProfileDetailsStyled>
            <div className="name">
              {sellerData?.seller_name?.split(" ")?.[0]}
            </div>
            <div>
              {sellerData?.seller_type}
              {sellerData?.seller_type && sellerData?.seller_id && " : "}
              {sellerData?.seller_id}
            </div>
          </HeaderProfileDetailsStyled>
          <HeaderProfileCard sellerData={sellerData} show={show} />
        </HeaderProfileContainerStyled>
      )}
    </>
  );
};

export default HeaderProfile;

export const HeaderProfileContainerStyled = styled.div`
  margin-left: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  @media (max-width: 900px) {
    margin-left: 10px;
  }
  @media (max-width: 425px) {
    margin-left: 0;
  }
`;
export const HeaderProfileDetailsStyled = styled.div`
  padding-left: 0.5rem;
  width: 100%;
  font-weight: 500;
  color: #404040;
  font-size: 0.65rem;
  .name {
    font-weight: 700;
  }
  @media (max-width: 425px) {
    display: none;
    margin: 0;
  }
`;
export const avatarStyles = {
  height: "30px",
  width: "30px",
  background: "var(--primary-color)",
  fontSize: "1rem",
  cursor: "pointer",
};
