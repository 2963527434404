/* eslint-disable eqeqeq */
import { useMutation, useQueries, useQuery, useQueryClient } from "react-query";
import api from "../api/api";
import useUrlQuery from "../hooks/useUrlQuery";
import _ from "lodash";
import { useDispatch, useSelector } from "react-redux";
import {
  setLeadData,
  setPlanType,
  setSumInsurer,
} from "../modules/landing.slice";
import { useLocation, useNavigate } from "react-router-dom";
import {
  setPrimaryProposerId,
  setProposalData,
  setProposalMembers,
} from "../modules/proposal.slice";
import { addQuoteToShortlist } from "../modules/quote.slice";
import { toast } from "react-toastify";

const useGetPlans = () => {
  const { getQuery } = useUrlQuery();
  const enquiry_id = getQuery("enquiry_id");
  const { userDetails } = useGetUserDetails();
  const {
    filters: { sum_insured },
  } = useSelector((state) => state.landing);
  const plans = useQueries(
    Object.keys(userDetails?.company_details || []).map((company) => ({
      queryKey: ["Plans", company, sum_insured],
      queryFn: () => {
        return api.post(`${company}/getPlans`, {
          sum_insured,
          enquiry_id,
        });
      },
    }))
  );
  return {
    plansData: plans
      .map((data) => data.data?.data.plan_data)
      .flat()
      .filter((item) => item),
    isPlanLoading: plans.some((item) => item.isLoading),
  };
};
const useGetUserDetails = () => {
  const { getQuery } = useUrlQuery();
  const enquiry_id = getQuery("enquiry_id");
  const dispatch = useDispatch();
  const { data, isLoading, refetch } = useQuery(
    ["Details"],
    () => api.get(`/get/enquiry/details?enquiry_id=${enquiry_id}`),
    {
      enabled: !!enquiry_id,
      onSuccess: (data) => {
        dispatch(
          setLeadData({
            ...data.data.data[0],
            country_name: data.data.data[0].travelling_to_country_name,
            travelling_to: data.data.data[0].travelling_to_country_id,
          })
        );
        if (data.data.data[0].sum_insured)
          dispatch(setSumInsurer(data.data.data[0].sum_insured));
        if (data.data.data[0].proposal_data) {
          dispatch(setProposalData(data.data.data[0].proposal_data.data));
        }
        if (data.data.data[0].primary_proposer_id) {
          dispatch(setPrimaryProposerId(data.data.data[0].primary_proposer_id));
        }
        const plan_type = data.data.data[0].plan_type;
        const travel_purpose_id = data.data.data[0].travel_purpose_id;

        if (plan_type === "Indivdual") {
          if (travel_purpose_id == "1") {
            dispatch(setPlanType("Individual Plan"));
          }
          if (travel_purpose_id == "4") {
            dispatch(setPlanType("Student Plan"));
          }
        }
        if (plan_type === "Floater") {
          dispatch(setPlanType("Family Plan"));
        }
        const members = [];
        if (data.data.data[0].proposer_age) {
          members?.push({
            label: "Self",
            value: "self",
          });
        }
        if (data.data.data[0].member_age_details?.spouse_age) {
          members?.push({
            label: "Spouse",
            value: "spouse",
          });
        }
        if (data.data.data[0].member_age_details?.child_1_age) {
          members?.push({
            label: "Child 1",
            value: "child_1",
          });
        }
        if (data.data.data[0].member_age_details?.child_2_age) {
          members?.push({
            label: "Child 2",
            value: "child_2",
          });
        }
        if (data.data.data[0].member_age_details?.parent_1_age) {
          members?.push({
            label: "Parent 1",
            value: "parent_1",
          });
        }
        if (data.data.data[0].member_age_details?.parent_2_age) {
          members?.push({
            label: "Parent 2",
            value: "parent_2",
          });
        }
        dispatch(setProposalMembers(members));
      },
    }
  );
  return {
    userDetails: { ...data?.data?.data[0] },
    country: data?.data?.data[0]?.travelling_to_country_name,
    members: data?.data?.data[0]?.no_of_traveller,
    startDate: data?.data?.data[0]?.trip_start_date,
    data: data?.data?.data[0],
    endDate: data?.data?.data[0]?.trip_end_date,
    userAge: data?.data?.data[0]?.proposer_age,
    memberAge: data?.data?.data[0]?.member_age_details,
    isDetailsLoading: isLoading,
    refetchUserDetails: refetch,
  };
};
const useGetQuotes = () => {
  const { getQuery } = useUrlQuery();
  const enquiry_id = getQuery("enquiry_id");
  const { plansData } = useGetPlans();
  const {
    filters: { insurers, sortBy },
  } = useSelector((state) => state.landing);
  const data = useQueries(
    plansData?.map((plan) => ({
      queryKey: ["Plans", plan?.id, plan?.company_alias, plan?.sum_insured],
      queryFn: () =>
        api.post(`${plan.company_alias}/getPremium`, {
          enquiry_id,
          plan_data: plan,
        }),
    }))
  );

  const isHighToLow = sortBy === "High To Low";
  return {
    quotes: _.groupBy(
      data
        ? data.map((data) =>
            data.error || !data?.data?.data?.status
              ? {
                  quoteData: {
                    ic_data: [
                      {
                        id: 0,
                      },
                    ],
                    data: [],
                    status: false,
                  },
                  isQuoteLoading: data?.isLoading,
                }
              : {
                  quoteData: data?.data?.data,
                  isQuoteLoading: data?.isLoading,
                }
          )
        : [],
      (data) => data?.quoteData?.ic_data[0]?.id
    ),
    sortedKeys: _.uniq(
      data
        ? data
            .filter((item) => {
              return (
                !insurers.length ||
                insurers.includes(String(item?.data?.data.ic_data[0].id))
              );
            })
            .sort((a, b) => {
              if (!a.data?.data?.data) {
                return 1;
              } else if (!b.data?.data?.data) {
                return -1;
              } else
                return isHighToLow
                  ? b?.data?.data?.data[0]?.total_premium_amount -
                      a?.data?.data?.data[0]?.total_premium_amount
                  : a?.data?.data?.data[0]?.total_premium_amount -
                      b?.data?.data?.data[0]?.total_premium_amount;
            })
            .map((item) => {
              return item.error || !item?.data?.data?.status
                ? 0
                : item?.data?.data?.ic_data[0]?.id;
            })
        : []
    ),
    quotes_by_insurer: _.groupBy(
      data
        ? data
            .filter((item) => {
              return (
                item?.data?.data.status &&
                (!insurers.length ||
                  insurers.includes(String(item?.data?.data.ic_data[0].id)))
              );
            })
            .map((data) =>
              data.error
                ? {
                    quoteData: {
                      ic_data: [
                        {
                          id: 0,
                        },
                      ],
                      data: [],
                      status: false,
                    },
                    isQuoteLoading: false,
                  }
                : {
                    quoteData: data?.data?.data,
                    isQuoteLoading: data?.isLoading,
                  }
            )
        : [],
      (data) => data?.quoteData?.ic_data[0]?.id
    ),
    allQuotes: data
      ? data
          .filter((item) => item?.data?.data.status)
          .map((item) => item?.data?.data)
      : [],

    count: data ? data.filter((item) => item?.data?.data.status).length : 0,
    isAnyQuoteLoading: data?.some((item) => item.isLoading),
  };
};
const useStoreSelectedPlanMutation = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const queryClient = useQueryClient();
  const { mutate, isLoading } = useMutation(
    (req) => api.post(`/store/selected/plan`, req),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(["Details"]);
        navigate({ pathname: "/proposal", search: location.search });
      },
    }
  );
  return {
    storeSelectedPlan: mutate,
    isStoreSelectedPlanLoading: isLoading,
  };
};
const useAddShortlisted = () => {
  const { mutate, isLoading } = useMutation((req) =>
    api.put(`/travel_shortlisted`, req)
  );
  return {
    addShortlistedPlan: mutate,
    isShortlistedPlanLoading: isLoading,
  };
};
const useGetKnowMore = () => {
  const { know_more_data } = useSelector((state) => state.quote);
  const product_id = know_more_data?.data?.[0]?.plan_id;
  const { data, isLoading } = useQuery(["KnowMore"], () => {
    return api.get(`travel_products/${product_id}/travel_knowmore_features`);
  });
  return {
    KnowMore: data?.data,
    isKnowMoreLoading: isLoading,
  };
};
const useGetShortlisted = () => {
  const { getQuery } = useUrlQuery();
  const enquiry_id = getQuery("enquiry_id");
  const { shortListedQuotes } = useSelector((state) => state.quote);
  const { isAnyQuoteLoading, quotes, allQuotes } = useGetQuotes();
  const dispatch = useDispatch();
  const { data, isLoading } = useQuery(
    ["Shortlisted"],
    () => api.get(`/travel_shortlisted?enquiry_id=${enquiry_id}`),
    {
      enabled:
        !isAnyQuoteLoading &&
        !!Object.keys(quotes).length &&
        !shortListedQuotes.length,
      onSuccess: ({ data }) => {
        const selectedProducts = data.data.products.map(
          (item) => item.product_id
        );
        allQuotes.forEach((quote) => {
          if (selectedProducts.includes(quote.data[0].plan_id)) {
            dispatch(addQuoteToShortlist(quote));
          }
        });
      },
    }
  );

  return {
    getShortlisted: data,
    isShortlistedLoading: isLoading,
  };
};
const useShareMutation = () => {
  const { getQuery } = useUrlQuery();
  const enquiry_id = getQuery("enquiry_id");
  const { mutate, isLoading } = useMutation(
    (req) => api.post("/quotes/travel/shareCQ", { ...req, enquiry_id }),
    {
      onSuccess: (variables) => {
        toast.success(
          <div style={{ fontSize: ".8rem", fontWeight: "600" }}>
            {variables?.data?.message || "Shared Successfully"} !!
          </div>
        );
      },
    }
  );

  return {
    share: mutate,
    isShareLoading: isLoading,
  };
};
const useGetCompareFeature = () => {
  const { compareQuote = [] } = useSelector((state) => state.quote);
  const data = useQueries(
    compareQuote?.map((item) => ({
      queryKey: ["Compare", item?.data?.[0]?.plan_id],
      queryFn: () =>
        api.get(
          `travel_products/${item?.data?.[0]?.plan_id}/travel_compare_features`
        ),
      staleTime: 5000,
    }))
  );
  return {
    comparisonData: data?.map((res) => res?.data?.data),
    isComparisonLoading: data.some((res) => res.isLoading),
  };
};

const useGetComparePdf = () => {
  const { getQuery } = useUrlQuery();
  const enquiry_id = getQuery("enquiry_id");
  const { data, mutate, isLoading } = useMutation(
    () => api.post(`/travel/compare/pdf_link`, { enquiry_id: enquiry_id }),
    {
      onSuccess: (data) => {
        window.open(data?.data?.data, "_blank");
      },
    }
  );
  return {
    getComparePdf: mutate,
    pdf: data?.data?.data,
    isComparePdfLoading: isLoading,
  };
};
const useAddQuoteToCompare = () => {
  const queryClient = useQueryClient();
  const { mutate, isLoading } = useMutation(
    (req) => api.put(`/travel_comparisons`, req),
    { onSuccess: () => queryClient.invalidateQueries(["Compare"]) }
  );
  return {
    addQuoteToCompare: mutate,
    isComparisonPlanLoading: isLoading,
  };
};

export {
  useShareMutation,
  useGetPlans,
  useGetQuotes,
  useGetUserDetails,
  useStoreSelectedPlanMutation,
  useAddQuoteToCompare,
  useGetCompareFeature,
  useAddShortlisted,
  useGetComparePdf,
  useGetShortlisted,
  useGetKnowMore,
};
