import {
  AppBar,
  Box,
  Button,
  Grid,
  Stack,
  Toolbar,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import Modal from "../Modal";
import { Route, Routes, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { setEditMode, setEditOpen } from "../../modules/quote.slice";
import EditDetails from "../TravelEdit/EditDetails";
import SendCompareB from "../../pages/ComparePage/components/SendCompareB";
import { useGetFrontendBoot } from "../../services/inputService";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import { toast } from "react-toastify";
import { setActiveStep } from "../../modules/landing.slice";
import HeaderData from "./HeaderData";
import SendProposalB from "../../pages/ProposalPage/components/SendProposalB";
import SendQuoteB from "../../pages/QuotesPage/components/SendQuoteB";
import HeaderProfile from "./HeaderProfile";

const logoPath = require(`../../assets/images/${process.env.REACT_APP_TENANT}Logo.png`);

const Header = ({ userData }) => {
  const { settings } = useGetFrontendBoot();
  const enquiryId = useSelector(
    (state) => state?.landing?.leadData?.enquiry_id
  );
  const editShow = useSelector((state) => state.quote.show_edit_quote);
  const { leadData } = useSelector((state) => state.landing);
  const dispatch = useDispatch();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));
  const navigate = useNavigate();
  const openEdit = () => dispatch(setEditOpen(true));
  const closeEdit = () => dispatch(setEditOpen(false));
  const closeEditModal = () => {
    dispatch(setEditOpen(false));
    dispatch(setEditMode("default"));
  };
  return (
    <>
      <Grid container>
        <AppBar
          sx={{
            boxShadow: "none",
            borderBottom: isMobile ? "none" : "2px solid var(--primary-light)",
            paddingRight: "0",
          }}
        >
          <Toolbar sx={{ justifyContent: "space-between" }}>
            <Box>
              <img
                src={settings?.logo || logoPath}
                style={{
                  cursor: "pointer",
                  paddingLeft: isMobile ? "0px" : "30px",
                }}
                onClick={() => {
                  navigate("/");
                  dispatch(setActiveStep(0));
                }}
                width={isMobile ? "124px" : "200px"}
                alt="Logo"
              />
            </Box>
            <Box
              sx={{ paddingRight: isMobile ? "0px" : "22px", display: "flex" }}
            >
              <Stack direction="row" spacing={-4}>
                <Routes>
                  <Route
                    exact
                    path="/quote"
                    element={
                      <Toolbar>
                        <HeaderData onClick={openEdit} />
                      </Toolbar>
                    }
                  />
                </Routes>
                {!isMobile && (
                  <Routes>
                    <Route
                      exact
                      path="/compare"
                      element={
                        <Toolbar>
                          <SendCompareB />
                        </Toolbar>
                      }
                    />
                  </Routes>
                )}
                {!isMobile && (
                  <Routes>
                    <Route
                      exact
                      path="/quote"
                      element={
                        <Toolbar>
                          <SendQuoteB />
                        </Toolbar>
                      }
                    />
                    <Route
                      exact
                      path="/shortlisted-quotes"
                      element={
                        <Toolbar>
                          <SendQuoteB />
                        </Toolbar>
                      }
                    />
                    {["/proposal", "/thank-you"]?.map((path) => (
                      <Route
                        exact
                        path={path}
                        element={
                          <>
                            <Toolbar>
                              <Button
                                sx={{
                                  height: "2.25rem",
                                  textTransform: "capitalize",
                                  cursor: "pointer",
                                }}
                                onClick={() => {
                                  navigator.clipboard.writeText(enquiryId);
                                  toast.success(
                                    <div
                                      style={{
                                        fontSize: ".8rem",
                                        fontWeight: "600",
                                      }}
                                    >
                                      Copied to clipboard
                                    </div>
                                  );
                                }}
                                variant="outlined"
                              >
                                <Stack
                                  spacing={0.5}
                                  direction={"row"}
                                  alignItems={"center"}
                                >
                                  <Typography
                                    sx={{
                                      fontSize: ".8rem",
                                      marginRight: "10px",
                                      color: "black",
                                      display: "flex",
                                    }}
                                  >
                                    CRN / Trace ID :{" "}
                                    <Typography
                                      sx={{
                                        fontSize: ".8rem",
                                        marginLeft: "10px",
                                        color: "var(--primary-color)",
                                        display: "flex",
                                      }}
                                    >
                                      {enquiryId}
                                    </Typography>
                                  </Typography>
                                  <ContentCopyIcon
                                    sx={{
                                      fontSize: "1rem",
                                      marginLeft: "10px",
                                      color: "grey",
                                    }}
                                  />
                                </Stack>
                              </Button>
                            </Toolbar>
                            {path === "/proposal" && (
                              <Toolbar>
                                <SendProposalB />
                              </Toolbar>
                            )}
                          </>
                        }
                      />
                    ))}
                  </Routes>
                )}
              </Stack>
              <HeaderProfile sellerData={userData || leadData} />
            </Box>
          </Toolbar>
        </AppBar>
      </Grid>
      <Modal maxWidth={"900px"} open={editShow} handleClose={closeEditModal}>
        <EditDetails handleClose={closeEdit} />
      </Modal>
    </>
  );
};

export default Header;
