import { useQuery, useMutation, useQueryClient } from "react-query";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import api from "../api/api";
import {
  setActiveStep,
  setFrontendBootData,
  setTraceId,
  setUuid,
} from "../modules/landing.slice";
import { toast } from "react-toastify";
import useUrlQuery from "../hooks/useUrlQuery";

const useGetCountries = () => {
  const { data, isLoading } = useQuery(["Countries"], () =>
    api.get("/get/countries")
  );
  return {
    countries: data?.data?.data,
    isCountriesLoading: isLoading,
  };
};
const useGetSellerInfo = () => {
  const { getQuery } = useUrlQuery();
  const token = getQuery("token");
  const { data, isLoading } = useQuery(
    ["User", token],
    () => api.post("dashboard/details/user", { token }),
    { enabled: !!token }
  );
  return {
    userData: data?.data,
    isUserLoading: isLoading,
  };
};
const useCreateQuote = () => {
  const { leadData } = useSelector((state) => state.landing);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { data, isLoading, mutate } = useMutation(
    (req) => api.post("/create/quote", { ...leadData, ...req }),
    {
      onSuccess: (data) => {
        dispatch(setTraceId(data?.data?.trace_id));
        navigate({
          pathname: "/quote",
          search: `?enquiry_id=${data.data.enquiry_id}`,
        });
      },
    }
  );

  return {
    isLoading,
    data,
    createQuote: mutate,
  };
};
const usePatchQuote = () => {
  const { leadData } = useSelector((state) => state.landing);
  const queryClient = useQueryClient();
  const { plan_type, max_annual_travel_days, ...remaining } = leadData;
  const { data, isLoading, mutate } = useMutation(
    (req) => api.patch("/update/quote", { ...remaining, ...req }),
    {
      onSuccess: (data) => {
        queryClient.invalidateQueries(["Details"]);
        queryClient.removeQueries("Plans");
        queryClient.refetchQueries("Plans");
      },
    }
  );

  return {
    isLoading,
    data,
    patchQuote: mutate,
  };
};
const useGetFrontendBoot = () => {
  const dispatch = useDispatch();
  const { data, isLoading } = useQuery(
    ["FrontendBoot"],
    () => api.get("/travel/frontend-boot"),
    {
      onSuccess: (data) => {
        dispatch(setFrontendBootData(data?.data));
      },
    }
  );

  return {
    frontendBoot: data?.data,
    settings: data?.data?.travelsettings,
    sum_insured: data?.data?.travelsuminsured,
    isFrontendBootLoading: isLoading,
  };
};
const useSendOTP = () => {
  const { leadData, frontendBootData } = useSelector((state) => state.landing);
  const dispatch = useDispatch();
  const { isLoading, mutate } = useMutation(
    (req) =>
      api.post(`/travel/${frontendBootData?.traveltenant?.alias}/sent_otp`, {
        ...req,
        name: leadData?.insured_name,
        mobile: leadData?.insured_mobile,
        email: leadData?.insured_email,
        section: "travel",
      }),
    {
      onSuccess: (data) => {
        dispatch(setUuid(data?.data?.uuid));
        data?.data?.uuid &&
          toast.success(
            <div style={{ fontSize: ".8rem", fontWeight: "600" }}>
              OTP Send Successfully !!
            </div>
          );
      },
    }
  );
  return {
    isSendOtpLoading: isLoading,
    sendOtp: mutate,
  };
};
const useVerifyOTP = () => {
  const { uuid, frontendBootData } = useSelector((state) => state.landing);
  const dispatch = useDispatch();
  const { isLoading, mutate } = useMutation(
    (req) =>
      api.post(`/travel/${frontendBootData?.traveltenant?.alias}/verify_otp`, {
        ...req,
        uuid,
      }),
    {
      onSuccess: (data) => {
        if (data?.data?.status) {
          dispatch(setActiveStep(1));
          toast.success(
            <div style={{ fontSize: ".8rem", fontWeight: "600" }}>
              Verified Successfully !!
            </div>
          );
        }
      },
      onError: (error) => {
        if (!error?.response?.data?.status)
          toast.error(
            <div style={{ fontSize: ".8rem", fontWeight: "600" }}>
              Incorrect OTP !! Check and try again.
            </div>
          );
      },
    }
  );

  return {
    isVerifyOtpLoading: isLoading,
    verifyOTP: mutate,
  };
};

export {
  useGetFrontendBoot,
  useGetCountries,
  useGetSellerInfo,
  useCreateQuote,
  usePatchQuote,
  useVerifyOTP,
  useSendOTP,
};
