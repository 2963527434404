/* eslint-disable eqeqeq */
import { useMutation, useQuery } from "react-query";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import api from "../api/api";
import useUrlQuery from "../hooks/useUrlQuery";
import {
  setActive,
  setPrimaryProposerId,
  setCKycStatus,
  setCKycFailCount,
  setCKycResponse,
  setCKycUploadStatus,
  setCKycUploadLoader,
} from "../modules/proposal.slice";
import { useGetUserDetails } from "./quoteService";
import { setMetaData } from "../modules/proposal.slice";
import _ from "lodash";

export const useStoreProposalMutation = ({ index }) => {
  const { userDetails } = useGetUserDetails();
  const { getQuery } = useUrlQuery();
  const dispatch = useDispatch();
  const enquiry_id = getQuery("enquiry_id");
  const { isLoading, data, mutate } = useMutation(
    (req) =>
      api.post("/store/proposal/detail", {
        ...req,
        enquiry_id,
        user_id: userDetails?.user_id,
        selected_plan_id: userDetails?.selected_plan?.id,
        insurance_company_id: userDetails?.selected_plan?.ic_id,
        ic_id: userDetails?.selected_plan?.ic_id,
      }),
    {
      onSuccess: (data, variables) => {
        if (data.data.data?.primary_proposer_id) {
          dispatch(setPrimaryProposerId(data?.data.data.primary_proposer_id));
        }
        if (data?.data?.status && variables?.ckyc_data?.ovdkyc) {
          dispatch(setCKycStatus(true));
        }
        if (data?.data?.status) {
          dispatch(setActive(index + 1));
        }
      },
    }
  );
  return {
    storeProposal: mutate,
    isLoading,
    p_data: data,
  };
};
export const useGetMedicalDetails = () => {
  const { userDetails } = useGetUserDetails();
  const ic_id = userDetails?.ic_detail?.ic_id;
  const { data, isLoading } = useQuery(
    ["MedicalQuestions"],
    () => api.post(`get/all/medical/question`, { ic_id }),
    { enabled: !!ic_id }
  );
  return {
    medicalQuestionsData: data?.data?.data,
    isMedicalQuestionsLoading: isLoading,
  };
};
export const useGetDiseases = () => {
  const { userDetails } = useGetUserDetails();
  const alias = userDetails?.ic_detail?.ic_alias;
  const { data, isLoading } = useQuery(
    ["Diseases"],
    () => api.post(`/${alias === "care_health" ? "religare" : alias}/diseases`),
    { enabled: !!alias }
  );
  return {
    diseases: data?.data?.data,
    isDiseaseLoading: isLoading,
  };
};
export const useRelationsQuery = () => {
  const { userDetails } = useGetUserDetails();
  const ic_id = userDetails?.ic_detail?.ic_id;
  const { data, isLoading } = useQuery(["Relation", 1], () =>
    api.post(
      "/get/master/relationship",
      { ic_id: ic_id || 1 },
      { enabled: !!ic_id }
    )
  );
  return {
    relationshipData: data?.data?.data,
    isRelationLoading: isLoading,
  };
};
export const useTataPaymentMutation = () => {
  const { data, isLoading, mutate } = useMutation((req) =>
    api.post("/tata/tagic_payment", req)
  );
  return {
    tataPayment: mutate,
    isLoading,
    tataPaymentData: data,
  };
};
export const useSubmitProposalMutation = () => {
  const dispatch = useDispatch();
  const { userDetails } = useGetUserDetails();
  const selected_plan = userDetails?.selected_plan;
  const { getQuery } = useUrlQuery();
  const enquiry_id = getQuery("enquiry_id");
  const { primary_proposer_id } = useSelector((state) => state.proposal);
  const { redirect } = useGetRedirectionMutation();
  const { mutate, isLoading } = useMutation(
    () => {
      return api.post(`save-proposal`, {
        enquiry_id,
        primary_proposer_id,
        ic_id: selected_plan?.ic_id,
        travel_insurance_id: selected_plan?.travel_insurance_id,
      });
    },
    {
      onSuccess: async (data) => {
        if (data?.data[0]?.status === true) {
          toast.success(
            <div style={{ fontSize: ".8rem", fontWeight: "600" }}>
              Proposal Submitted Successfully
            </div>
          );
        } else {
          toast.error(
            <div style={{ fontSize: ".8rem", fontWeight: "600" }}>
              {data?.data[0]?.message || "Failed to Save Proposal"}
            </div>
          );
        }
        if (data?.data[0]?.data?.payment_url) {
          window.open(data?.data[0]?.data?.payment_url, "_self");
          return;
        }
        if (selected_plan?.ic_id === 1 && data?.data[0]?.status) {
          const careForm = document.createElement("form");
          careForm.action = `https://apiuat.religarehealthinsurance.com/portalui/PortalPayment.run`;
          careForm.method = "post";
          careForm.target = "_self";
          careForm.style.display = "none";
          const input1 = document.createElement("input");
          input1.name = "proposalNum";
          input1.type = "hidden";
          input1.value = data?.data?.[0]?.data?.proposalNum;
          careForm.appendChild(input1);
          const input2 = document.createElement("input");
          input2.name = "returnURL";
          input2.type = "hidden";
          input2.value =
            process.env.REACT_APP_API_BASE_URL +
            `religare-verify-payment?enquiry_id=${userDetails?.enquiry_id}`;
          careForm.appendChild(input2);
          document.body.appendChild(careForm);
          careForm.submit();
          document.body.removeChild(careForm);
          return;
        }
        if (selected_plan?.ic_id !== 1 && data?.data[0]?.status) {
          redirect({
            enquiry_id,
            payment_id: data.data[0].data?.payment_id,
          });
        }
        if (selected_plan?.ic_id === 6 && data?.data[0]?.status === false) {
          dispatch(setCKycFailCount());
          dispatch(setMetaData(data?.data[0]));
        }
      },
    }
  );

  return {
    submitProposal: mutate,
    isProposalSubmitLoading: isLoading,
  };
};
export const useGetPolicyPDFMutation = () => {
  const { userDetails } = useGetUserDetails();
  const selected_plan = userDetails?.selected_plan;
  const { getQuery } = useUrlQuery();
  const enquiry_id = getQuery("enquiry_id");
  const { primary_proposer_id } = useSelector((state) => state.proposal);
  const { data, mutate, isLoading, refetch } = useMutation(
    () => {
      return api.post(`get-policy`, {
        enquiry_id,
        primary_proposer_id,
        ic_id: selected_plan?.ic_id,
        travel_insurance_id: selected_plan?.travel_insurance_id,
      });
    },
    {
      enabled: !!enquiry_id || !!primary_proposer_id || !!selected_plan,
      onSuccess: async (data) => {
        if (data?.data[0]?.status === true) {
          toast.success(
            <div style={{ fontSize: ".8rem", fontWeight: "600" }}>
              Policy PDF Generated Successfully
            </div>
          );
        } else {
          toast.error(
            <div style={{ fontSize: ".8rem", fontWeight: "600" }}>
              {data?.data[0]?.message || "Failed to Generate Policy PDF"}
            </div>
          );
        }
      },
      onError: () => {
        toast.error(
          <div style={{ fontSize: ".8rem", fontWeight: "600" }}>
            Failed to Download policy PDF. Please try again later.
          </div>
        );
      },
    }
  );

  return {
    getPolicyPDF: mutate,
    policyPDFData: data,
    isPolicyPDFLoading: isLoading,
    refetchPolicyPDF: refetch,
  };
};
export const useGetRedirectionMutation = () => {
  const navigate = useNavigate();
  const { userDetails } = useGetUserDetails();
  const selected_plan = userDetails?.selected_plan;
  const { getQuery } = useUrlQuery();
  const enquiry_id = getQuery("enquiry_id");
  const { mutate } = useMutation(
    (data) => {
      return api.post("quote/redirection", data);
    },
    {
      onSuccess: async (data) => {
        const { redirection_url, redirection_method, redirection_params } =
          data.data.data[0];
        const form = document.createElement("form");
        if (redirection_method === "POST") {
          form.setAttribute("action", redirection_url);
          form.setAttribute("method", redirection_method);
          const inputData = document.createElement("input");
          inputData.name = "pgiRequest";
          inputData.value = redirection_params;
          form.appendChild(inputData);
          document.body.appendChild(form);
          form.submit();
          document.body.removeChild(form);
        } else if (redirection_method === "GET") {
          window.open(redirection_url, "_self");
        } else {
          const { data: order } = await api.post("create-order", {
            enquiry_id,
          });
          const options = {
            key: process.env.REACT_APP_KEY_ID_RAZORPAY_TEST, // Enter the Key ID generated from the Dashboard
            amount: order.data.amount, // Amount is in currency subunits. Default currency is INR. Hence, 50000 refers to 50000
            currency: "INR",
            name: userDetails.ic_detail?.ic_name || "Fyntune Solution",
            description: "Travel Insurance",
            image: userDetails.ic_detail?.logo,
            order_id: order.data.id, //This is a sample Order ID. Pass the `id` obtained in the response of Step 1
            handler: async function (response) {
              fetch(`${process.env.REACT_APP_API_BASE_URL}save-logs`, {
                keepalive: true,
                method: "POST",
                headers: {
                  "Content-Type": "application/json",
                },
                body: JSON.stringify({
                  ...response,
                  order_id: order.data.id,
                  payment_id: order.paymentId,
                  razorpay_order_id: order.data.id,
                  enquiry_id,
                }),
              });
              await api.post("verify-payment", {
                ...response,
                order_id: order.data.id,
                payment_id: order.paymentId,
              });
            },
            modal: {
              ondismiss: function () {
                toast.error(
                  <div style={{ fontSize: ".8rem", fontWeight: "600" }}>
                    Payment Failed !!
                  </div>
                );
              },
            },
            prefill: {
              email: userDetails.proposer_email,
              contact: userDetails.proposer_mobile_no,
            },
            theme: { color: "#45b4d9" },
          };
          let paymentObject = new window.Razorpay(options);
          paymentObject.on("payment.failed", function ({ error }) {});
          paymentObject.open();
          if (data.data[0].status) {
            fetch(`${process.env.REACT_APP_API_BASE_URL}travel_policy_pdf`, {
              keepalive: true,
              method: "PUT",
              headers: { "Content-Type": "application/json" },
              body: JSON.stringify({
                enquiry_id,
                ic_id: selected_plan?.ic_id,
                policy_id: data.data[0].data?.policy_id,
                payment_id: data.data[0].data?.payment_id,
              }),
            });
            navigate("/thank-you");
          }
        }
      },
    }
  );
  return { redirect: mutate };
};
export const useCkycSubmitMutation = () => {
  const { userDetails } = useGetUserDetails();
  const leadData = useSelector((state) => state.landing.leadData);
  const { getQuery } = useUrlQuery();
  const dispatch = useDispatch();
  const enquiry_id = getQuery("enquiry_id");
  const { mutate, isLoading } = useMutation(
    (req) => {
      dispatch(setCKycUploadLoader({ [req?.docType]: true }));
      return api.post("/ckyc-verifications", {
        ic_id: userDetails?.selected_plan.ic_id,
        company_alias: leadData?.ic_detail?.ic_alias,
        enquiry_id,
        section: "travel",
        type: "api",
        ...req,
      });
    },
    {
      onSuccess: (data, variables, context) => {
        dispatch(setCKycUploadLoader({ [variables?.docType]: false }));
        dispatch(setCKycResponse(data?.data?.data?.data));
        if (data?.data?.data?.data?.verification_status == false) {
          dispatch(setCKycFailCount());
          toast.error(
            <div style={{ fontSize: ".8rem", fontWeight: "600" }}>
              CKYC Verification Failed !!
            </div>
          );
        }

        if (
          data?.data?.data?.data?.verification_status == true ||
          data?.data?.verification_status === true
        ) {
          dispatch(setCKycStatus(true));
        }
        if (data?.data?.status && _.isEmpty(data?.data?.data?.data)) {
          dispatch(setCKycUploadStatus({ [variables?.docType]: true }));
          toast.success(
            <div style={{ fontSize: ".8rem", fontWeight: "600" }}>
              Document Uploaded Successfully !!
            </div>
          );
        }
      },
      onError: ({ error, variables, context }) => {
        dispatch(setCKycUploadLoader({ [variables?.docType]: false }));
        dispatch(setCKycUploadStatus({ [variables?.docType]: false }));
        toast.error(
          <div style={{ fontSize: ".8rem", fontWeight: "600" }}>
            Document Uploaded Failed !!
          </div>
        );
      },
    }
  );

  return {
    cKycValidate: mutate,
    isLoading,
  };
};
