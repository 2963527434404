export const careSalutations = [
  { code: "1", display_name: "Mr" },
  { code: "2", display_name: "Ms" },
];
export const identityProofOptions = [
  { code: "1", display_name: "PAN Card" },
  { code: "2", display_name: "Voter ID" },
  { code: "3", display_name: "Driving License" },
  { code: "4", display_name: "Passport" },
];
export const addressProofOptions = [
  { code: "1", display_name: "Voter ID" },
  { code: "2", display_name: "Driving License" },
  { code: "3", display_name: "Passport" },
];
export const addressProofOptionsDummy = [
  { id: "Voter ID", label: "Voter ID" },
  { id: "Driving License", label: "Driving License" },
  { id: "Passport", label: "Passport" },
  { id: "Other", label: "Other" },
];
export const careMedicalQuestions = [
  {
    code: 1,
    question: "Does any person(s) to be insured has any Pre-existing diseases?",
    description_question_type: "select",
    // description_question_type: "select"||"text"||"none",
    description_question:
      "If Pre-existing diseases, please mention the details",
    description_question_options: [
      {
        id: 128,
        label: "Liver Disease",
        value: "PEDliverDetailsTravel",
      },
      {
        id: 114,
        label: "Cancer / Tumor",
        value: "PEDcancerDetailsTravel",
      },
      {
        id: 143,
        label: "Coronary Artery Heart Disease",
        value: "PEDHealthDiseaseTravel",
      },
      {
        id: 129,
        label: "Kidney Disease",
        value: "PEDkidneyDetailsTravel",
      },
      {
        id: 164,
        label: "Paralysis / Stroke",
        value: "PEDparalysisDetailsTravel",
      },
      {
        id: 210,
        label: "Others",
        value: "PEDotherDetailsTravel",
      },
    ],
    other_selected_question: "Please Specify",
  },
  {
    code: 2,
    question:
      "Has anyone been diagnosed / hospitalized or under any treatment for any illness / injury during the last 48 months?",
    description_question_type: "text",
    description_question: "Please Specify",
    description_question_options: [],
  },
  {
    code: 3,
    question: "Have you ever claimed under any travel policy?",
    description_question_type: "text",
    description_question: "Please Specify",
    description_question_options: [],
  },
];
