import {
  Box,
  Grid,
  Paper,
  Stack,
  Typography,
  Button,
  AccordionDetails,
  AccordionSummary,
  Accordion,
} from "@mui/material";
import React from "react";
import MobileC from "./components/MobileC";
import pdf from "../../assets/images/pdf.jpg";
import { useGetUserDetails } from "../../services/quoteService";
import { useSelector } from "react-redux";
import { CheckBoxOutlined } from "@mui/icons-material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

export default function MobileCard({ setShow, setShowForm }) {
  const { userDetails } = useGetUserDetails();
  const selected_plan = userDetails?.selected_plan;
  const features = selected_plan?.plan_details
    ? selected_plan?.plan_details[0]?.plan_feature
    : [];
  const [...otherFeatures] = features;
  const featuresToShow = otherFeatures
    .filter((item) => item.feature_values)
    .flat()
    ?.filter((item) => item.feature_section_description !== "Plan Details");
  const { know_more_data } = useSelector((state) => state.quote);
  const { ic_data } = know_more_data;
  return (
    <Paper sx={{ pb: 10, boxShadow: "none" }}>
      <MobileC setShow={setShow} setShowForm={setShowForm} />
      {featuresToShow?.length && (
        <Box sx={{ m: 1, mt: 2 }}>
          <Accordion sx={{ p: 0.1 }}>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon sx={{ color: "#0a0938" }} />}
            >
              <Typography
                sx={{
                  fontSize: ".8rem",
                  fontWeight: "bold",
                  color: "#0a0938",
                }}
              >
                Coverage Details
              </Typography>
            </AccordionSummary>
            <AccordionDetails sx={{ py: 1, pt: 0 }}>
              {featuresToShow.map((feature) => (
                <Box sx={{ py: 0.75 }} key={feature.feature_key}>
                  <Subhead title={feature.feature_key} />
                </Box>
              ))}
            </AccordionDetails>
          </Accordion>
        </Box>
      )}
      <Box sx={{ padding: "10px", mt: 1 }}>
        <TitleInfo
          target={"_blank"}
          download="Policy Wording"
          href={ic_data && ic_data[0].policy_wording}
        >
          Download Policy Wording
        </TitleInfo>
        <TitleInfo
          target={"_blank"}
          download="Brochure"
          href={ic_data && ic_data[0]?.travel_broucher}
        >
          Download Brochure
        </TitleInfo>
      </Box>
      <Button
        fullWidth
        color="secondary"
        sx={{
          textTransform: "none",
          borderRadius: 0,
          position: "fixed",
          bottom: 0,
          left: 0,
          right: 0,
        }}
      >
        <Stack
          justifyContent="space-between"
          alignItems="center"
          spacing={10}
          direction="row"
        >
          <Typography fontSize="14px" color="#fff">
            Total Premium Payable
          </Typography>
          <Stack justifyContent="center">
            <Typography fontSize="22px" color="#fff" whiteSpace={"nowrap"}>
              <span style={{ fontFamily: "Inter" }}>₹</span>{" "}
              {selected_plan?.total_premium?.toLocaleString("en-IN")}
            </Typography>
            <Typography fontSize="10px" color="#fff">
              Excluding GST
            </Typography>
          </Stack>
        </Stack>
      </Button>
    </Paper>
  );
}

const Subhead = ({ title }) => {
  return (
    <Stack alignItems={"center"} spacing={2} direction="row">
      <CheckBoxOutlined color="primary" />
      <Typography
        variant="body1"
        sx={{ fontSize: "11px", fontWeight: "bold", color: "#000000" }}
      >
        {title}
      </Typography>
    </Stack>
  );
};

const TitleInfo = ({ download, target, children, href }) => {
  return (
    <Button
      variant="Text"
      target={target}
      download={download}
      href={href}
      fullWidth
      sx={{ mb: 2, borderRadius: 1, boxShadow: 2 }}
    >
      <Grid alignItems="center" container spacing={1}>
        <Grid item xs={9}>
          <Typography
            sx={{
              fontSize: "11px",
              fontWeight: "bold",
              color: "#0a0938",
            }}
            gutterBottom
          >
            {children}
          </Typography>
        </Grid>
        <Grid item xs={3}>
          <img src={pdf} alt="pdf" style={{ width: "28px", height: "28px" }} />
        </Grid>
      </Grid>
    </Button>
  );
};
