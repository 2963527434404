import { Box, Stack, Typography } from "@mui/material";

export const Title = ({ children }) => {
  return (
    <Stack flexDirection="row">
      <Box
        sx={{
          width: "2%",
          border: "2px",
          backgroundColor: "rgba(58, 116, 173, 0.22)",
        }}
      ></Box>
      <Box
        sx={{
          width: "98%",
          backgroundImage: "linear-gradient(98deg, #E8E8E8, white)",
          pl: 2,
          pt: 1,
        }}
      >
        <Typography variant="h6" gutterBottom sx={{ fontSize: ".9rem" }}>
          {children}
        </Typography>
      </Box>
    </Stack>
  );
};
