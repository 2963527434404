import { Box, Button, CircularProgress, Typography } from "@mui/material";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import useUrlQuery from "../../hooks/useUrlQuery";
import {
  useGetShortlisted,
  useGetUserDetails,
} from "../../services/quoteService";

const ShortlistedCard = () => {
  const { shortListedQuotes } = useSelector((state) => state.quote);
  const { isShortlistedLoading } = useGetShortlisted();
  return (
    <>
      <Box
        width="100%"
        sx={{
          border: "2px solid #B6C5E7",
          borderRadius: "10px",
        }}
      >
        {isShortlistedLoading ? (
          <CircularProgress />
        ) : (
          <>
            {" "}
            <Box
              p={1}
              fontSize=".9rem"
              borderBottom={"1.5px solid rgba(165, 176, 250, 0.5)"}
              display="flex"
              justifyContent="space-between"
            >
              <Box>
                Shortlisted Quotes {shortListedQuotes.length ? `:` : ""}
              </Box>
              <Box sx={{ fontWeight: "normal", color: "var(--primary-color)" }}>
                {shortListedQuotes.length
                  ? `${shortListedQuotes.length} Plan${
                      shortListedQuotes.length > 1 ? "s" : ""
                    } Added`
                  : ""}
              </Box>
            </Box>
            <Box
              sx={{
                background: "var(--primary-light)",
                borderBottomLeftRadius: "10px",
                borderBottomRightRadius: "10px",
              }}
              p={2}
            >
              {shortListedQuotes?.length ? (
                <ShortlistedQuote
                  quote={shortListedQuotes[shortListedQuotes.length - 1]}
                />
              ) : (
                <NoShortListedQuotes />
              )}
            </Box>
          </>
        )}
      </Box>
    </>
  );
};
const NoShortListedQuotes = () => {
  return (
    <Box sx={{ textAlign: "center", p: 1 }}>
      <Typography variant="h5" fontSize="12px" fontWeight={500} gutterBottom>
        Shortlisted plans will be available once shortlisted
      </Typography>
    </Box>
  );
};
const PairValue = ({ label, value }) => {
  return (
    <Box display={"flex"} justifyContent="space-between">
      <Typography variant="h5" fontSize="10px" fontWeight={500}>
        {label} :
      </Typography>
      <Typography variant="h5" fontSize="12px" fontWeight={600}>
        {value}
      </Typography>
    </Box>
  );
};
const ShortlistedQuote = ({ quote }) => {
  const navigate = useNavigate();
  const { getQuery } = useUrlQuery();
  const enquiry_id = getQuery("enquiry_id");
  const { userDetails } = useGetUserDetails();

  return (
    <>
      <Box display={"flex"} alignItems="center">
        <Box
          width="25%"
          height="60px"
          sx={{
            background: "#fff",
            p: "2px",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <img
            src={
              userDetails.company_details[quote.ic_data[0].company_alias].logo
            }
            alt=""
            style={{
              width: "100%",
              height: "auto",
            }}
          />
        </Box>
        <Box
          width="75%"
          gap="6px"
          px={1}
          display="inline-flex"
          flexDirection={"column"}
        >
          {/* <PairValue label='Loss of Passport' value={"$ 100"} />
          <PairValue label='Baggage Loss' value={"$ 200"} />
          <PairValue label='Medical Expenses' value={"$ 300"} /> */}
          <PairValue
            label="Sum Insured"
            value={
              "$ " + Number(quote.data[0].sum_insured).toLocaleString("en-US")
            }
          />
          <PairValue
            label="Premium"
            value={
              <span>
                <span style={{ fontFamily: "Inter" }}>₹</span>
                {" " +
                  Number(quote.data[0].total_premium_amount).toLocaleString(
                    "en-US"
                  )}
              </span>
            }
          />
        </Box>
      </Box>
      <Box mt={1}>
        <Button
          variant="outlined"
          fullWidth
          sx={{
            background: "#fff",
            color: "#000",
            textTransform: "capitalize",
            borderRadius: "100px",
          }}
          onClick={() => {
            navigate(`/shortlisted-quotes?enquiry_id=${enquiry_id}`);
          }}
        >
          View Shortlisted Plans
        </Button>
      </Box>
    </>
  );
};
export default ShortlistedCard;
