import { Stack } from "@mui/material";
import { useFormikContext } from "formik";
const CustomRadio = ({ name, children, value, checked, onChange }) => {
  const { setFieldValue } = useFormikContext();
  return (
    <>
      <input
        type="radio"
        className="radio"
        name={name}
        onChange={() => {
          setFieldValue(name, value);
        }}
        id={name + value}
        checked={checked}
      />
      <label htmlFor={name + value}>
        <>
          <Stack spacing={1}>{children}</Stack>
          <div className="radio__button"></div>
        </>
      </label>
    </>
  );
};

export default CustomRadio;
