export const documentOptions = [
  { code: "aadhaar", display_name: "Aadhar Card" },
  { code: "ckyc_number", display_name: "CKYC Number" },
  { code: "vid", display_name: "Voter ID" },
  { code: "license", display_name: "Driving License" },
  { code: "passport", display_name: "Passport Number" },
];

export const tataMedicalQuestions = [
  {
    code: 1,
    question: "Does any person(s) to be insured has any Pre-existing diseases?",
    description_question_type: "select",
    // description_question_type: "select"||"text"||"none",
    description_question:
      "If Pre-existing diseases, please mention the details",
    description_question_options: [
      {
        id: 1,
        label: "Auto Immune Diseases",
        value: "Auto Immune Diseases",
      },
      {
        id: 2,
        label: "Cancer",
        value: "Cancer",
      },
      {
        id: 3,
        label: "Cerebrovascular Accident (Stroke)",
        value: "Cerebrovascular Accident (Stroke)",
      },
      {
        id: 4,
        label: "Chronic Heart Disease",
        value: "Chronic Heart Disease",
      },
      {
        id: 5,
        label: "Chronic Kidney Disease",
        value: "Chronic Kidney Disease",
      },
      {
        id: 6,
        label: "Chronic Liver Disease",
        value: "Chronic Liver Disease",
      },
      {
        id: 7,
        label: "Chronic Obstructive Pulmonary Disease (COPD)",
        value: "Chronic Obstructive Pulmonary Disease (COPD)",
      },
      {
        id: 8,
        label: "Deep Vein Thrombosis (DVT)",
        value: "Deep Vein Thrombosis (DVT)",
      },
      {
        id: 9,
        label: "Diabetic Neuropathy",
        value: "Diabetic Neuropathy",
      },
      {
        id: 10,
        label: "Epilepsy & Seizures",
        value: "Epilepsy & Seizures",
      },
      {
        id: 11,
        label: "Rheumatoid Arthritis",
        value: "Rheumatoid Arthritis",
      },
      {
        id: 12,
        label: "Others",
        value: "Others",
      },
    ],
    sub_description_question_type: "date",
    sub_description_question: "Suffering since",
    other_selected_question: "Please Specify",
  },
  // {
  //   code: 2,
  //   question:
  //     "Has anyone been diagnosed / hospitalized or under any treatment for any illness / injury during the last 48 months?",
  //   description_question_type: "none",
  //   description_question: "",
  //   other_selected_question: "",
  //   sub_description_question_type: "",
  //   sub_description_question: "",
  // },
  // {
  //   code: 3,
  //   question: "Have you ever claimed under any travel policy?",
  //   description_question_type: "none",
  //   description_question: "",
  //   other_selected_question: "",
  //   sub_description_question_type: "",
  //   sub_description_question: "",
  // },
];
