export const featureSections = [
  {
    name: "Plan Details",
    tooltip:
      "Plan details outline the specific coverage, benefits, and exclusions of an insurance policy",
  },
  {
    name: "Medical Related Covers",
    tooltip:
      "Medical-related benefits cover expenses for medical emergencies, treatments, and evacuation during travel",
  },
  {
    name: "Trip Related Covers",
    tooltip:
      "Trip-related benefits include coverage for trip cancellations, medical emergencies, and lost baggage",
  },
  {
    name: "Baggage Related Covers",
    tooltip:
      "Baggage-related covers provide compensation for lost, stolen, or damaged luggage and personal items.",
  },
  {
    name: "Additional Benefits",
    tooltip:
      "Additional benefits include emergency assistance, rental car coverage, and trip delay compensation",
  },
];
