/* eslint-disable eqeqeq */
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Stack,
  Typography,
} from "@mui/material";
import React from "react";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

export default function TrCover({ name, data }) {
  return (
    <Stack sx={{ pb: 15 }} spacing={1}>
      <Box
        sx={{
          overflow: "auto",
          maxHeight: "500px",
          mb: 10,
          "&::-webkit-scrollbar": {
            width: "0.4em",
          },
        }}
      >
        {data.map((item) => {
          return (
            item && (
              <Box>
                <Accordion
                  sx={{
                    borderBottom: "1px solid rgba(165, 176, 250, 0.5)",
                    minHeight: "50px",
                    boxShadow: "none",
                    pointerEvents: item.feature_key_description
                      ? "auto"
                      : "none",
                  }}
                >
                  <AccordionSummary
                    expandIcon={
                      item.feature_key_description && (
                        <ExpandMoreIcon sx={{ color: "black" }} />
                      )
                    }
                  >
                    <Stack>
                      <Typography variant="h6" fontSize="14px" fontWeight="400">
                        {item.cover}
                      </Typography>
                      <Stack alignItems="center" direction="row" spacing={1}>
                        <Typography
                          variant="h6"
                          fontSize="16px"
                          fontWeight="500"
                        >
                          {item.feature_key_title}
                        </Typography>
                        <span style={{ fontSize: "16px", fontWeight: "500" }}>
                          {item.feature_key_value && (
                            <> - {item.feature_key_value}</>
                          )}
                        </span>
                      </Stack>
                    </Stack>
                  </AccordionSummary>
                  <AccordionDetails sx={{ pt: 0 }}>
                    <Typography fontSize="14px" color="#000000">
                      {item.feature_key_description}
                    </Typography>
                  </AccordionDetails>
                </Accordion>
              </Box>
            )
          );
        })}
      </Box>
    </Stack>
  );
}
