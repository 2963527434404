import { Box, Typography, useMediaQuery } from "@mui/material";
import { Formik } from "formik";
import React from "react";
import { useDispatch } from "react-redux";
import ButtonWrapper from "../InputComponents/ButtonWrapper";
import CustomRadio from "../InputComponents/CustomRadio";
import { setSumInsurer } from "../../modules/landing.slice";
import { useTheme } from "@emotion/react";
import { usePatchQuote } from "../../services/inputService";
import useUrlQuery from "../../hooks/useUrlQuery";
import { Title } from "./Title";

export default function SumInsuredPop({ handleClose, sumInsuredArray }) {
  const dispatch = useDispatch();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));
  const isTablet = useMediaQuery(theme.breakpoints.between("sm", "md"));
  const { getQuery } = useUrlQuery();
  const enquiry_id = getQuery("enquiry_id");
  const { patchQuote } = usePatchQuote();
  return (
    <Box>
      <Title>Select Your Sum Insured</Title>
      <Formik
        initialValues={{ sum_insured: "" }}
        onSubmit={(values) => {
          dispatch(setSumInsurer(values.sum_insured));
          patchQuote({
            sum_insured: values.sum_insured,
            enquiry_id,
          });
          handleClose();
        }}
      >
        <Box>
          <Box maxHeight={"280px"} overflow={"scroll"}>
            {sumInsuredArray?.map((item) => (
              <CustomRadio name="sum_insured" value={`${item.value}`}>
                <Typography
                  sx={{
                    fontSize: isTablet ? "16px" : isMobile ? "12px" : "15px",
                    color: "#8282a1",
                  }}
                  variant="body1"
                  gutterBottom
                >
                  $ {item?.value}
                </Typography>
              </CustomRadio>
            ))}
          </Box>
          <ButtonWrapper>Update</ButtonWrapper>{" "}
        </Box>
      </Formik>
    </Box>
  );
}
