import { Paper, Box, useMediaQuery, Typography } from "@mui/material";
import React from "react";
import StepperComp from "../../components/Stepper/StepperComp";
import { activeStepSelector } from "../../modules/landing.slice";
import { useSelector } from "react-redux";

import { useTheme } from "@emotion/react";
import { MainWrapper } from "./components";
import { JourneyDetails, PersonalDetails, TravelPurpose } from "./forms";

const InputPage = ({ userData }) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));
  const activeStep = useSelector(activeStepSelector);

  return (
    <MainWrapper>
      <Paper
        sx={{
          p: 2,
          mt: 3,
          border: isMobile ? "1px solid #E4E4E4" : "2px solid #E4E4E4",
          boxShadow: "none",
          borderRadius: "10px",
        }}
      >
        <Box sx={{ width: { sm: "100%", md: "70%" }, mx: "auto" }}>
          <StepperComp />
        </Box>
        {activeStep === 0 && <PersonalDetails />}
        {activeStep === 1 && <TravelPurpose />}
        {activeStep === 2 && <JourneyDetails userData={userData} />}
      </Paper>
      {activeStep === 0 && (
        <Typography
          component={"h6"}
          sx={{
            fontSize: ".9rem",
            mt: 1,
            mb: 2,
            "& span": {
              color: "var(--primary-color)",
              fontSize: "1rem",
              border: "none",
            },
          }}
        >
          ✈️ Exclusively for International Journeys!
        </Typography>
      )}
    </MainWrapper>
  );
};

export default InputPage;
