import React from "react";
import { Card, Typography } from "@mui/material";

export default function RecommendCard() {
  return (
    <Card
      sx={{
        py: 0.5,
        px: 1.5,
        border: "1px solid var(--primary-color)",
        borderRadius: "10px",
        backgroundColor: "#DBE7FC",
        boxShadow: "none",
      }}
    >
      <Typography
        color={"var(--primary-color)"}
        fontSize={"12px"}
        fontWeight="500"
        variant="h6"
        whiteSpace={"nowrap"}
      >
        Hi, Here's Our Plan On The Basis Of The Details You Have Provided
      </Typography>
    </Card>
  );
}
