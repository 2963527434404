import BajajFooter from "./BajajFooter";
import TataFooter from "./TataFooter";

const Footer = ({ tenant }) => {
  return tenant?.name === "TMIBASL" ? (
    <TataFooter />
  ) : tenant?.name === "Bajaj" ? (
    <BajajFooter />
  ) : (
    <></>
  );
};

export default Footer;
