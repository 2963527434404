import * as yup from "yup";
import { createTheme, TextField, ThemeProvider, useTheme } from "@mui/material";
import { DatePicker as Picker } from "@mui/x-date-pickers/";
import { useField, useFormikContext } from "formik";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import moment from "moment";
import { forwardRef, useState } from "react";
import styled from "styled-components";
export const modifyDetails = ({ minAge, maxAge }) =>
  yup.object({
    dob: yup
      .string()
      .required("Date of Birth is required")
      .min(14, "Please enter a valid date")
      .test("dob", "Age should be between 18 and 65", (value) => {
        const age = moment().diff(moment(value, "DD/MM/YYYY"), "years");
        return age >= minAge && age <= maxAge;
      }),
  });
const DatePicker = forwardRef(
  (
    {
      name,
      extraMsg,
      error,
      readOnly,
      selectOnly,
      openPickerIcon,
      placeholder,
      noAgeWrapper = false,
      ...props
    },
    ref
  ) => {
    const [field, meta] = useField(name);
    const checkValue = (str, max) => {
      if (str.charAt(0) !== "0" || str === "00") {
        var num = parseInt(str);
        if (isNaN(num) || num <= 0 || num > max) num = 1;
        str =
          num > parseInt(max.toString().charAt(0)) &&
          num.toString().length === 1
            ? "0" + num
            : num.toString();
      }
      return str;
    };
    const getAge = (value) => {
      if (value) {
        let date = value.replace(/ /g, "");
        let a = moment();
        date = moment(value, "DD/MM/YYYY");
        return a.diff(date, "y");
      } else {
        return 0;
      }
    };
    const dateUtil = (val) => {
      let input = val;
      if (/\D\/$/.test(input)) input = input.substr(0, input.length - 3);
      var values = input.split("/").map(function (v) {
        return v.replace(/\D/g, "");
      });
      if (values[0]) values[0] = checkValue(values[0], 31);
      if (values[1]) values[1] = checkValue(values[1], 12);
      var output = "";

      output = values.map(function (v, i) {
        return v.length === 2 && i < 2 ? v + " / " : v;
      });
      val = output.join("").substr(0, 14);

      if (val.length === 14) {
        let age = getAge(val);
        return age;
      }
    };
    const { setFieldValue, values } = useFormikContext();
    const [age, setAge] = useState(null);
    const onChange = (val) => {
      const age = dateUtil(moment(val).format("DD/MM/YYYY"));
      setAge(age);
      setFieldValue(name, moment(val).format("YYYY-MM-DD"));
    };
    const parentTheme = useTheme();
    let tempDate = values?.[name] ? moment(values?.[name], "YYYY-MM-DD") : null;
    if (name.includes(".")) {
      tempDate = name?.split(".")?.reduce((acc, key) => acc?.[key], values)
        ? moment(
            name?.split(".")?.reduce((acc, key) => acc?.[key], values),
            "YYYY-MM-DD"
          )
        : null;
    }
    const config = {
      inputFormat: "DD/MM/YYYY",
      ...field,
      ...props,
      fullWidth: true,
      onChange,
      value: tempDate,
      placeholder: placeholder || "DD/MM/YYYY",
    };

    if (meta && meta.touched && meta.error) {
      config.error = true;
      config.helperText = meta.error;
    }
    const theme = createTheme({ palette: parentTheme.palette });
    return (
      <>
        <ThemeProvider theme={theme}>
          <Picker
            ref={ref}
            size={props.isLanding && "small"}
            components={{ OpenPickerIcon: openPickerIcon || CalendarMonthIcon }}
            OpenPickerButtonProps={{ sx: { color: `var(--primary-color)` } }}
            onAccept={props?.onAccept}
            onClose={props?.onClose}
            {...config}
            inputProps={{
              ...config?.inputProps,
              readOnly: selectOnly,
              placeholder: config?.placeholder,
              sx: {
                fontSize: ".85rem",
                fontFamily: "Poppins",
                fontWeight: "600",
                color: "#000",
                letterSpacing: ".5px",
                "& .MuiPopper-root": {
                  position: "relative",
                  backgroundColor: "red",
                },
              },
            }}
            renderInput={(params) => (
              <TextField
                fullWidth
                size={props.isLanding && "small"}
                required={props.required}
                inputProps={{ readOnly: selectOnly }}
                sx={{
                  ...(props.isLanding && {
                    "& .MuiInputBase-root": {
                      height: "45px",
                      width: "100%",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      paddingBottom: "5px",
                    },
                    "& .MuiOutlinedInput-root": {
                      legend: { display: "none" },
                    },
                  }),
                  ...(!props.isLanding && {
                    "& .MuiOutlinedInput-root": {
                      height: "60px",
                      color: config.error ? "" : "#000",
                    },
                  }),
                  "& .MuiOutlinedInput-root.Mui-focused": {
                    "& > fieldset": {
                      border: readOnly && "1px solid #8282a1",
                    },
                  },
                }}
                {...params}
                InputLabelProps={{
                  style: {
                    fontSize: ".9rem",
                    fontFamily: "Poppins",
                    paddingTop: "5px",
                    color: config.error ? "" : "#8282a1",
                  },
                }}
                helperText={config.helperText}
                error={config.error}
                placeholderText={config.placeholder}
              />
            )}
          />
          {extraMsg && !error && <span>{extraMsg}</span>}
          {!noAgeWrapper && !props?.isLanding && age > 0 && !error && (
            <AgeWrapper>{age + `${age > 1 ? " years" : " year"}`}</AgeWrapper>
          )}
        </ThemeProvider>
      </>
    );
  }
);
const AgeWrapper = styled.p`
  position: relative;
  z-index: 10;
  height: 0;
  width: 100px;
  top: -38px;
  left: 64%;
  font-size: 14px;
  color: var(--primary-color);
  font-family: "Poppins";
  margin: 0;
`;
export default DatePicker;
