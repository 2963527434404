import {
  Button,
  Card,
  Grid,
  Stack,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import {
  clearCompareQuote,
  removeQuoteFromCompare,
} from "../../../modules/quote.slice";
import cancel from "../../../assets/images/cancel.png";
import { useNavigate } from "react-router-dom";
import useUrlQuery from "../../../hooks/useUrlQuery";
import { useGetUserDetails } from "../../../services/quoteService";
import StartIcon from "@mui/icons-material/Start";
import CloseIcon from "@mui/icons-material/Close";

export default function ComparePopup() {
  const compareQuote = useSelector((state) => state.quote.compareQuote);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { getQuery } = useUrlQuery();
  const enquiry_id = getQuery("enquiry_id");
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));
  const isTablet = useMediaQuery(theme.breakpoints.between("sm", "md"));
  const { userDetails } = useGetUserDetails();

  return (
    <Card
      sx={{
        p: 1,
        boxShadow: 10,
        position: "fixed",
        bottom: 0,
        left: 0,
        right: 0,
        width: "100%",
        border: "2px solid",
        zIndex: 10,
      }}
    >
      <Grid justifyContent="center" alignItems="center" container>
        <Grid
          item
          xs={5}
          md={3.5}
          justifyContent={"center"}
          alignItems={"center"}
        >
          <Card
            sx={{
              p: 2,
              mr: 2,
              minHeight: isMobile ? 90 : 95,
              display: "flex",
              justifyItems: "center",
              alignItems: "center",
              border: ".5px dashed",
            }}
          >
            {compareQuote[0]?.ic_data[0]?.id ? (
              <Grid
                container
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <Grid item xs={11.5} md={11}>
                  <Stack alignItems={"center"} spacing={2} direction="row">
                    <img
                      style={{ padding: 1 }}
                      src={
                        userDetails.company_details[
                          compareQuote[0].ic_data[0].company_alias
                        ].logo
                      }
                      width={isTablet ? "60px" : isMobile ? "25px" : "50px"}
                      height={"100%"}
                      alt=""
                    />
                    <Stack spacing={1}>
                      <Typography
                        variant="h6"
                        fontSize={isTablet ? "12px" : isMobile ? "8px" : "10px"}
                        fontWeight={800}
                      >
                        {compareQuote[0].data[0]?.plan_name}
                      </Typography>
                      <Typography
                        variant="h6"
                        fontSize={isTablet ? "12px" : isMobile ? "8px" : "10px"}
                        sx={{ color: "gray" }}
                        fontWeight={600}
                      >
                        Sum Insured : {compareQuote[0].data[0].sum_insured}
                      </Typography>
                    </Stack>
                  </Stack>
                </Grid>
                <Grid item xs={0.5} md={1}>
                  <img
                    src={cancel}
                    style={{
                      width: isMobile ? "10px" : "15px",
                      height: isMobile ? "10px" : "15px",
                      padding: 1,
                      mt: 0,
                    }}
                    alt=""
                    onClick={() =>
                      dispatch(removeQuoteFromCompare(compareQuote[0]))
                    }
                  />
                </Grid>
              </Grid>
            ) : (
              <Typography
                color="gray"
                variant="h6"
                fontSize="16px"
                textAlign="center"
                fontWeight={600}
              >
                Select Plan To Compare
              </Typography>
            )}
          </Card>
        </Grid>
        <Grid
          item
          xs={5}
          md={3.5}
          justifyContent={"center"}
          alignItems={"center"}
        >
          <Card
            sx={{
              p: 2,
              mr: 2,
              minHeight: 95,
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              border: ".5px dashed",
            }}
          >
            {compareQuote[1]?.ic_data[0]?.id ? (
              <Grid
                container
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <Grid item xs={11} md={11}>
                  <Stack alignItems={"center"} spacing={2} direction="row">
                    <img
                      style={{ padding: 1 }}
                      src={
                        userDetails.company_details[
                          compareQuote[1].ic_data[0].company_alias
                        ].logo
                      }
                      width={isTablet ? "60px" : isMobile ? "25px" : "50px"}
                      height={"100%"}
                      alt=""
                    />
                    <Stack spacing={1}>
                      <Typography
                        variant="h6"
                        fontSize={isTablet ? "12px" : isMobile ? "8px" : "10px"}
                        fontWeight={800}
                      >
                        {compareQuote[1].data[0]?.plan_name}
                      </Typography>
                      <Typography
                        variant="h6"
                        fontSize={isTablet ? "12px" : isMobile ? "8px" : "10px"}
                        sx={{ color: "gray" }}
                        fontWeight={600}
                      >
                        Sum Insured : {compareQuote[1].data[0].sum_insured}
                      </Typography>
                    </Stack>
                  </Stack>
                </Grid>
                <Grid item xs={1} md={1}>
                  <img
                    src={cancel}
                    style={{
                      width: isMobile ? "10px" : "15px",
                      height: isMobile ? "10px" : "15px",
                      padding: 1,
                    }}
                    onClick={() =>
                      dispatch(removeQuoteFromCompare(compareQuote[1]))
                    }
                    alt=""
                  />
                </Grid>
              </Grid>
            ) : (
              <Typography
                color="gray"
                variant="h6"
                fontSize="16px"
                textAlign="center"
                fontWeight={600}
              >
                Select Plan To Compare
              </Typography>
            )}
          </Card>
        </Grid>
        {!isMobile && (
          <Grid
            item
            xs={5}
            md={3.5}
            justifyContent={"center"}
            alignItems={"center"}
          >
            <Card
              sx={{
                p: 2,
                mr: 2,
                minHeight: 95,
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                border: ".5px dashed",
              }}
            >
              {compareQuote[2]?.ic_data[0]?.id ? (
                <Grid
                  container
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <Grid item xs={11}>
                    <Stack
                      display={"flex"}
                      alignItems={"center"}
                      spacing={2}
                      direction="row"
                      justifyContent={"center"}
                    >
                      <img
                        style={{ padding: 1 }}
                        src={
                          userDetails.company_details[
                            compareQuote[2].ic_data[0].company_alias
                          ].logo
                        }
                        width="50px"
                        height={"100%"}
                        alt=""
                      />
                      <Stack spacing={1}>
                        <Typography
                          variant="h6"
                          fontSize={
                            isTablet ? "12px" : isMobile ? "8px" : "10px"
                          }
                          fontWeight={800}
                        >
                          {compareQuote[2].data[0]?.plan_name}
                        </Typography>
                        <Typography
                          variant="h6"
                          fontSize={
                            isTablet ? "12px" : isMobile ? "8px" : "10px"
                          }
                          sx={{ color: "gray" }}
                          fontWeight={600}
                        >
                          Sum Insured : {compareQuote[2].data[0].sum_insured}
                        </Typography>
                      </Stack>
                    </Stack>
                  </Grid>
                  <Grid item xs={1}>
                    <img
                      src={cancel}
                      style={{
                        width: "15px",
                        height: "15px",
                        padding: 1,
                      }}
                      alt=""
                      onClick={() =>
                        dispatch(removeQuoteFromCompare(compareQuote[2]))
                      }
                    />
                  </Grid>
                </Grid>
              ) : (
                <Typography
                  color="gray"
                  variant="h6"
                  fontSize="16px"
                  textAlign="center"
                  fontWeight={600}
                >
                  Select Plan To Compare
                </Typography>
              )}
            </Card>
          </Grid>
        )}
        <Grid
          item
          xs={1}
          md={0.5}
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "column",
            m: 0.5,
          }}
        >
          <Button
            sx={{
              m: 0.5,
              minWidth: "50px",
              backgroundColor: "var(--primary-color)",
              color: "#fff",
              "&:hover": {
                backgroundColor: "var(--primary-color)",
                color: "#fff",
              },
              "&:disabled": {
                color: "#777",
                backgroundColor: "silver",
              },
            }}
            variant="outlined"
            color="primary"
            disabled={compareQuote?.length <= 1}
            onClick={() => {
              navigate({
                pathname: "/compare",
                search: `?enquiry_id=${enquiry_id}`,
              });
            }}
          >
            <StartIcon />
          </Button>
          <Button
            sx={{
              m: 0.5,
              minWidth: "50px",
              color: "var(--primary-color)",
              backgroundColor: "#fff",
            }}
            variant="outlined"
            color="primary"
            onClick={() => dispatch(clearCompareQuote())}
          >
            <CloseIcon />
          </Button>
        </Grid>
      </Grid>
    </Card>
  );
}
