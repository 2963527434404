/* eslint-disable eqeqeq */
import {
  Alert,
  Button,
  FormHelperText,
  Grid,
  Grow,
  InputLabel,
  Typography,
  useTheme,
} from "@mui/material";
import { Formik } from "formik";
import { useDispatch, useSelector } from "react-redux";
import Checkbox from "../../../components/InputComponents/Checkbox";
import DatePicker from "../../../components/InputComponents/DatePicker";
import MultiSelect from "../../../components/InputComponents/MultiSelect";
import RadioButton from "../../../components/InputComponents/RadioButton";
import { setActiveStep, setLeadData } from "../../../modules/landing.slice";
import moment from "moment";
import {
  useCreateQuote,
  useGetCountries,
} from "../../../services/inputService";
import FormikSideEffect from "../../../hoc/FormikSideEffect";
import * as yup from "yup";
import React, { Fragment, useState } from "react";
import { booleanOptions } from "../../../utils/options";
import FlightLandIcon from "@mui/icons-material/FlightLand";
import FlightTakeoffIcon from "@mui/icons-material/FlightTakeoff";
import { setUserData } from "../../../modules/quote.slice";
import { useGetUserDetails } from "../../../services/quoteService";
import ButtonLoader from "../../../components/ButtonLoader/ButtonLoader";
import BackButton from "../../../components/BackButton/BackButton";
import useUrlQuery from "../../../hooks/useUrlQuery";
import { SelectDays } from "../components";

const JourneyDetails = ({ userData }) => {
  const theme = useTheme();
  const dispatch = useDispatch();
  const details = useGetUserDetails();
  const { createQuote, isLoading } = useCreateQuote();
  const { countries, isCountriesLoading } = useGetCountries();
  const [endDate, setEndDate] = useState(0);
  const { leadData } = useSelector((state) => state.landing);
  const [datePickerOpen, setDatePickerOpen] = useState(false);
  const [openToDate, setOpenToDate] = useState(moment());
  const [student, setStudent] = useState();
  const { getQuery } = useUrlQuery();
  const token = getQuery("token");
  const initialValues = {
    country: leadData?.country,
    trip_start_date: leadData?.trip_start_date,
    trip_end_date: leadData?.trip_end_date,
    multitrip: leadData?.multitrip || "N",
    max_annual_travel_days: leadData?.max_annual_travel_days || 30,
    agree: true,
  };
  const validationSchema = yup.object().shape({
    country: yup
      .array()
      .test("country", "Country is required", (value) => value?.length),
    agree: yup
      .bool()
      .test("agree", "Trip should start from India", (value) => value),
    trip_start_date: yup
      .string()
      .nullable()
      .required("Start date is required")
      .test(
        "trip_start_date",
        "Trip start date should be greater than today",
        function (value) {
          return moment(value, "YYYY-MM-DD").isAfter(
            moment().subtract(1, "days")
          );
        }
      )
      .test(
        "trip_start_date",
        "Trip start date should be less than trip end date",
        function (value) {
          return moment(value, "YYYY-MM-DD").isBefore(
            this.parent.trip_end_date
          );
        }
      ),
    trip_end_date: yup.string().nullable().required("End date is required"),
    max_annual_travel_days: yup.string().when("multitrip", {
      is: "Y",
      then: yup.string().required("Max annual travel days is required"),
    }),
  });

  if (isCountriesLoading) {
    return <div>Loading...</div>;
  }
  return (
    <>
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={async (values) => {
          const payload = {
            ...values,
            step: 1,
            max_annual_travel_days: values?.max_annual_travel_days || 30,
            travel_frequency:
              values?.multitrip === "Y" ? "Annual Multi Trip" : "Single Trip",
            travel_frequency_id: values?.multitrip === "Y" ? 2 : 1,
            travel_days:
              values?.multitrip === "Y"
                ? 365
                : moment(values?.trip_end_date).diff(
                    moment(values?.trip_start_date),
                    "days"
                  ),
            country_name: values?.country.map((item) => item.name),
            travelling_to: values?.country.map((item) => item.id),
            broker_name: "Alliance",
            ...(userData && { params: { ...userData, token } }),
          };
          await dispatch(setLeadData(payload));
          dispatch(setUserData(details));
          createQuote({ ...values });
        }}
      >
        {({ values, handleSubmit, setFieldValue, errors }) => (
          <Grid container rowSpacing={2} columnSpacing={2}>
            <Grid sx={{ mt: -5 }} item xs={1} md={1}>
              <BackButton
                onClick={() => {
                  dispatch(setActiveStep(1));
                }}
              />
            </Grid>
            <Grid item md={12} sm={12}>
              <Typography
                variant="h3"
                fontSize={{
                  xs: "16px",
                  md: "20px",
                }}
                component="p"
                my={2}
              >
                Where do you want to travel
              </Typography>
              <MultiSelect
                fullWidth={true}
                options={countries || []}
                name="country"
                isLoading={isCountriesLoading}
                placeholder="Select Countries*"
              />
              <Checkbox
                name="agree"
                label="The traveler(s) are residents of India and the trip should start from India"
              />
              <FormHelperText sx={{ color: "error.main" }}>
                {errors.agree}
              </FormHelperText>
            </Grid>

            <Grid item md={6} sm={6} xs={12}>
              <InputLabel>Date of Travel (Start Date)</InputLabel>
              <FormikSideEffect
                effect={() => {
                  if (values?.trip_start_date) {
                    // Get the last day of the current month using moment
                    const lastDayOfMonth = moment(
                      values?.trip_start_date
                    ).endOf("month");
                    // If the selected date is the last day of the month, move to the next month
                    if (
                      moment(values?.trip_start_date).isSame(
                        lastDayOfMonth,
                        "day"
                      )
                    ) {
                      setOpenToDate(
                        moment(values?.trip_start_date).add(1, "month")
                      );
                    }
                  }
                }}
                dependencies={[values?.trip_start_date]}
              >
                <DatePicker
                  onAccept={() => setDatePickerOpen(true)}
                  name="trip_start_date"
                  isLanding
                  disablePast
                  placeholder="Start Date*"
                  openPickerIcon={FlightTakeoffIcon}
                />
              </FormikSideEffect>
            </Grid>

            <Grid item sm={6} md={6} xs={12}>
              <InputLabel>Date of Travel (End Date)</InputLabel>
              <FormikSideEffect
                effect={() => {
                  if (
                    values?.trip_start_date &&
                    values?.trip_end_date &&
                    moment(values?.trip_end_date).diff(
                      moment(values?.trip_start_date),
                      "day"
                    ) > 179 &&
                    values?.multitrip === "N"
                  ) {
                    setStudent(true);
                  } else {
                    setStudent(false);
                  }
                }}
                dependencies={[values?.trip_start_date, values?.trip_end_date]}
              >
                <DatePicker
                  open={datePickerOpen}
                  onOpen={() => setDatePickerOpen(true)}
                  onAccept={() => setDatePickerOpen(false)}
                  onClose={() => setDatePickerOpen(false)}
                  isLanding
                  name="trip_end_date"
                  disabled={
                    values?.multitrip === "Y" || !values?.trip_start_date
                  }
                  minDate={
                    values?.multitrip !== "Y" &&
                    moment(values?.trip_start_date, "YYYY-MM-DD").add(1, "day")
                  }
                  maxDate={
                    values?.multitrip !== "Y" &&
                    moment(values?.trip_start_date, "YYYY-MM-DD").add(
                      3,
                      "years"
                    )
                  }
                  placeholder="End Date*"
                  openPickerIcon={FlightLandIcon}
                  defaultCalendarMonth={openToDate}
                  required
                />
              </FormikSideEffect>
            </Grid>
            {values?.trip_start_date && (
              <Grid item md={12}>
                <FormikSideEffect
                  effect={() => {
                    if (values?.multitrip === "Y") {
                      setEndDate(1);
                      setFieldValue(
                        "trip_end_date",
                        moment(values?.trip_start_date, "YYYY-MM-DD")
                          .add(1, "year")
                          .subtract(1, "day")
                          .format("YYYY-MM-DD")
                      );
                    } else if (values?.multitrip === "N" && endDate == 1) {
                      setFieldValue("trip_end_date", null);
                    }
                  }}
                  dependencies={[values?.multitrip, values?.trip_start_date]}
                >
                  {datePickerOpen ? (
                    <>
                      <RadioButton
                        name="multitrip"
                        label="Are you traveling to these countries multiple times in a year?"
                        options={booleanOptions}
                      />
                    </>
                  ) : (
                    <>
                      <RadioButton
                        name="multitrip"
                        label="Are you traveling to these countries multiple times in a year?"
                        options={booleanOptions}
                      />
                    </>
                  )}
                </FormikSideEffect>
              </Grid>
            )}
            {values?.multitrip === "Y" && (
              <Grow in={values?.multitrip === "Y"}>
                <Grid item md={12}>
                  <SelectDays />
                </Grid>
              </Grow>
            )}
            <Grid item xs={12} md={12} mt={values?.multitrip === "Y" ? 0 : 4}>
              <Button
                type="submit"
                variant="contained"
                color="secondary"
                fullWidth
                sx={{
                  mt: values?.multitrip === "Y" ? 1 : -5,
                  transition: theme.transitions.create("all"),
                  borderRadius: "6px",
                  backgroundColor: "var(--primary-color)",
                }}
                onClick={handleSubmit}
              >
                {isLoading ? <ButtonLoader /> : "View Quotes"}
              </Button>
            </Grid>
          </Grid>
        )}
      </Formik>
      {student && (
        <Alert
          severity="info"
          action={
            <Fragment>
              <button
                style={{
                  backgroundColor: "var(--primary-color)",
                  color: "white",
                  borderColor: "var(--primary-color)",
                  borderRadius: "4px",
                  padding: "10px",
                }}
                onClick={() => setStudent(false)}
              >
                <text>Confirm & Proceed</text>
              </button>
            </Fragment>
          }
        >
          <h6>Student Overseas Travel Insurance Plan</h6>
          <p style={{ fontSize: "12px" }}>
            You can avail a travel insurance cover for duration of 180 days or
            more only if you are a student and want to buy a student overseas
            travel insurance policy.
          </p>
        </Alert>
      )}
    </>
  );
};

export default JourneyDetails;
