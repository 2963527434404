import { Box, Card, Grid, Tab, Tabs, Typography } from "@mui/material";
import React, { useState } from "react";
import TabPanel from "../../../../components/TabPanel/TabPanel";
import TrCover from "./TrCover";
import { useGetKnowMore } from "../../../../services/quoteService";

export default function TableKYP() {
  const { KnowMore } = useGetKnowMore();
  const [value, setValue] = useState(0);
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  const [active, setActive] = useState(0);

  return (
    <Box>
      <Grid container justifyContent="center" spacing={0}>
        <Grid sx={{ mt: 5, mb: 5 }} item xs={3}>
          <Tabs
            onChange={handleChange}
            value={value}
            TabIndicatorProps={{ style: { display: "none" } }}
            orientation="vertical"
            sx={{
              "& .MuiTabs-flexContainer": {
                justifyContent: "center",
                gap: "10px",
              },
            }}
          >
            {KnowMore?.know_more[0]?.map(
              (item, idx) =>
                item.feature_key && (
                  <Tab
                    disableRipple
                    sx={{ textTransform: "none" }}
                    onClick={() => setActive(idx)}
                    label={
                      <Label change={active === idx}>{item.feature_key}</Label>
                    }
                  />
                )
            )}
          </Tabs>
        </Grid>
        <Grid item xs={9}>
          {KnowMore?.know_more[0]?.map((item, idx) => {
            return (
              <TabPanel value={value} index={idx}>
                <TrCover data={item?.feature_values} name={item.feature_key} />
              </TabPanel>
            );
          })}
        </Grid>
      </Grid>
    </Box>
  );
}

const Label = ({ children, change }) => {
  return (
    <Card
      sx={{
        border: change ? 0.5 : 1,
        backgroundColor: change ? "var(--primary-light)" : "#fff",
        borderColor: change
          ? "var(--primary-color)"
          : "rgba(165, 176, 250, 0.5)",
        pt: 2,
        pb: 2,
        maxWidth: "100%",
        boxShadow: 0,
        borderRadius: 1,
        width: "220px",
      }}
    >
      <Typography
        fontWeight="500"
        fontSize={"16px"}
        variant="h6"
        color={change ? "var(--primary-color)" : "black"}
      >
        {children}
      </Typography>
    </Card>
  );
};
