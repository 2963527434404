/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from "react";

const CountdownTimer = ({
  timeLeft,
  setTimeLeft,
  setIsOtpModalOpen,
  setOtp,
}) => {
  useEffect(() => {
    if (timeLeft <= 0) {
      setOtp("");
      setIsOtpModalOpen(false);
      setTimeLeft(5 * 60);
      return;
    }
    const intervalId = setInterval(() => {
      setTimeLeft((prevTime) => prevTime - 1);
    }, 1000);
    return () => clearInterval(intervalId);
  }, [timeLeft]);
  const minutes = Math.floor(timeLeft / 60);
  const seconds = timeLeft % 60;
  return (
    <span style={{ color: "var(--primary-color)" }}>
      {`${minutes}:${seconds < 10 ? `0${seconds}` : seconds}`}
    </span>
  );
};

export default CountdownTimer;
