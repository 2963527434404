import {
  Grid,
  Tooltip,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { Box } from "@mui/system";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import FeatureRow from "./FeatureRow";

const FeatureCard = ({
  title,
  tooltip = "",
  showOnlySelected,
  handleSelectedFeature,
  rowsData = {},
}) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  return (
    <>
      <Box
        width="100%"
        sx={{
          border: " 1px solid rgba(0, 0, 0, 0.3)",
          borderRadius: "10px",
        }}
        mt={2}
      >
        <Typography
          variant="h6"
          sx={{
            background: "rgba(165, 176, 250, 0.5)",
            borderRadius: "9px 0px",
            display: "inline-block",
            padding: "6px 12px",
            fontSize: isMobile ? ".75rem" : ".85rem",
            fontWeight: "600",
            color: "var(--primary-color)",
            mb: 2,
          }}
        >
          {title}
          <Tooltip title={tooltip} arrow placement="top">
            <InfoOutlinedIcon
              sx={{
                height: isMobile ? ".9rem" : "1rem",
                width: isMobile ? ".9rem" : "1rem",
                ml: isMobile ? 0 : 1,
                mt: 0,
              }}
            />
          </Tooltip>
        </Typography>
        <Grid container spacing={1}>
          {Object.keys(rowsData)?.map((rowData) => {
            return (
              <FeatureRow
                handleSelectedFeature={handleSelectedFeature}
                key={`${title}_${rowsData?.[rowData]?.[0]?.name}`}
                parent={title}
                feature_label={rowsData?.[rowData]?.[0]?.name}
                description={rowsData?.[rowData]?.[0]?.key_description}
                dataValues={rowsData?.[rowData]}
                showOnlySelected={showOnlySelected}
              />
            );
          })}
        </Grid>
      </Box>
    </>
  );
};

export default FeatureCard;
