/* eslint-disable react-hooks/exhaustive-deps */
import { Dialog, DialogContent, DialogTitle, Stack } from "@mui/material";
import EmailIcon from "@mui/icons-material/Email";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";
import SmsIcon from "@mui/icons-material/Sms";
import { useEffect, useState } from "react";
import { useGetUserDetails } from "../../services/quoteService";
import { useDispatch, useSelector } from "react-redux";
import { setShowCompareModal } from "../../modules/quote.slice";
import InputField from "./InputField";

const ShareCompareQuoteModal = () => {
  const { userDetails } = useGetUserDetails();
  const [formData, setFormData] = useState({
    email: userDetails?.proposer_email,
    sms: userDetails.proposer_mobile_no,
    whatsapp: userDetails.proposer_mobile_no,
  });
  const { showCompareModal } = useSelector((state) => state.quote);
  const onFieldChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };
  const dispatch = useDispatch();
  const [updateOnce, setUpdateOnce] = useState(false);
  useEffect(() => {
    if (userDetails.proposer_email && !updateOnce) {
      setFormData({
        email: userDetails?.proposer_email,
        sms: userDetails.proposer_mobile_no,
        whatsapp: userDetails.proposer_mobile_no,
      });
      setUpdateOnce(true);
    }
  }, [userDetails]);
  return (
    <>
      <Dialog
        open={showCompareModal}
        onClose={() => dispatch(setShowCompareModal(false))}
        maxWidth={"sm"}
        fullWidth
      >
        <DialogTitle>Share Compare Quotes</DialogTitle>
        <DialogContent>
          <Stack py={2} spacing={2}>
            <InputField
              onChange={onFieldChange}
              placeholder={"test@test.com"}
              value={formData.email}
              name={"email"}
              type={"email"}
              Icon={EmailIcon}
              purpose={"compare"}
              page={"compare-page"}
            />
            <InputField
              onChange={onFieldChange}
              placeholder={"9*********0"}
              value={formData.sms}
              name={"sms"}
              type={"tel"}
              inputProps={{ maxLength: "10" }}
              Icon={SmsIcon}
              purpose={"compare"}
              page={"compare-page"}
            />
            <InputField
              onChange={onFieldChange}
              placeholder={"9*********0"}
              name={"whatsapp"}
              type={"tel"}
              value={formData.whatsapp}
              Icon={WhatsAppIcon}
              inputProps={{ maxLength: "10" }}
              purpose={"compare"}
              page={"compare-page"}
            />
          </Stack>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default ShareCompareQuoteModal;
