import { FilterAltSharp } from "@mui/icons-material";
import {
  Card,
  Stack,
  Box,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import React from "react";

export default function Insurer({ label, subtitle, handleClick, ...props }) {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));
  const isTablet = useMediaQuery(theme.breakpoints.between("sm", "md"));
  if (isMobile) {
    return (
      <Box
        id="basic-card"
        onClick={handleClick}
        sx={{
          maxWidth: "100%",
          maxHeight: "48px",
          pl: 0.5,
          pr: 0.5,
          boxShadow: "none",
          justifyContent: "center",
          alignItems: "center",
          height: isMobile ? "auto" : "56px",
          width: isMobile ? "100%" : "100%",
          ":hover": {
            cursor: "pointer",
          },
        }}
        disableRipple
        variant="flat"
      >
        <Stack
          alignItems="center"
          direction={isMobile ? "column" : "row"}
          justifyContent={isMobile ? "center" : "space-between"}
          px={1}
          height="100%"
        >
          <Typography
            sx={{
              fontSize: isTablet ? "18px" : "10px",
              fontWeight: "00",
              // marginLeft: "4px",
            }}
            color="#333333"
            variant="h6"
          >
            Insurer :{" "}
          </Typography>
          <Stack direction="row" alignItems="center" marginLeft={"5px"}>
            <Typography
              color={"var(--primary-color)"}
              sx={{ fontSize: isTablet ? "18px" : "12px", fontWeight: "500" }}
            >
              {" "}
              {subtitle}{" "}
            </Typography>
          </Stack>
        </Stack>
      </Box>
    );
  }
  return (
    <Card
      id="basic-card"
      onClick={handleClick}
      sx={{
        width: "19%",
        borderRight: "1px solid  #E4E4E4",
        borderRadius: "0px",
      }}
      disableRipple
      variant="flat"
    >
      <Stack
        alignItems="center"
        direction={isMobile ? "column" : "row"}
        justifyContent={isMobile ? "center" : "center"}
        px={1}
        height="100%"
      >
        <Typography
          sx={{
            fontSize: isMobile ? "10px" : "12px",
            fontWeight: "400",
            whiteSpace: "nowrap",
          }}
          color="#333333"
          variant="h6"
        >
          Insurer :{" "}
        </Typography>
        <Stack
          direction="row"
          alignItems="center"
          marginLeft={isMobile ? "0" : ".2rem"}
        >
          <Typography
            sx={{
              fontSize: isTablet ? "16px" : isMobile ? "12px" : "12px",
              fontWeight: "600",
              whiteSpace: "nowrap",
            }}
          >
            {subtitle}{" "}
          </Typography>
          {isMobile ? null : (
            <FilterAltSharp
              sx={{
                width: isMobile ? "20px" : "20px",
                height: "40px",
                color: "#333333",
              }}
            />
          )}
        </Stack>
      </Stack>
    </Card>
  );
}
