import {
  Box,
  Button,
  Card,
  Grid,
  Paper,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { Form, Formik } from "formik";
import TextInput from "../../../components/InputComponents/TextInput";
import Title from "../components/FormTitle";
import DatePicker from "../../../components/InputComponents/DatePicker";
import * as yup from "yup";
import Select from "../../../components/InputComponents/Select";
import {
  useRelationsQuery,
  useStoreProposalMutation,
} from "../../../services/proposalService";
import { useDispatch, useSelector } from "react-redux";
import { setProposalData } from "../../../modules/proposal.slice";
import moment from "moment";
import {
  allowFullName,
  getMaxDateByAge,
  getMinDateByAge,
} from "../../../utils/input";
import SummaryData from "../components/SummaryData";
import Summary from "../components/Summary";
import ButtonLoader from "../../../components/ButtonLoader/ButtonLoader";
const members = [{ value: "self", label: "Self" }];
const NomineeDetails = ({ index }) => {
  const theme = useTheme();
  const dispatch = useDispatch();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const { storeProposal, isLoading } = useStoreProposalMutation({ index });
  const { relationshipData } = useRelationsQuery();
  const { primary_proposer_id, proposalData, active } = useSelector(
    (state) => state.proposal
  );
  const relations = relationshipData?.NomineeRelationship?.map((item) => ({
    code: item.value,
    display_name: item.name,
    id: item.id,
  }));
  const nomineeFormData = proposalData?.[`nominee`];
  const initialValues = members.reduce((acc, curr) => {
    return {
      ...acc,
      [curr.value]: {
        nominee_name: "",
        nominee_dob: "",
        nominee_relation_with_proposer: "",
      },
    };
  }, {});
  const validationMembersSchema = yup.object().shape({
    ...members.reduce((acc, curr) => {
      return {
        ...acc,
        [curr.value]: yup.object().shape({
          nominee_name: yup
            .string()
            .max(60, "Must be less than 60 chars")
            .required("Nominee Name is required"),
          nominee_dob: yup.string().required("Date of Birth is required"),
          nominee_relation_with_proposer: yup
            .string()
            .required("Relation with proposer is required"),
        }),
      };
    }, {}),
  });
  if (index < active) {
    return (
      <Summary title="Nominee Details" index={index}>
        {members.map((member) => (
          <>
            {members.length > 1 && (
              <Grid item xs={12}>
                <Typography variant="h4" gutterBottom>
                  {member?.label}
                </Typography>
              </Grid>
            )}
            <SummaryData
              label="Nominee Name*"
              value={nomineeFormData[member?.value].nominee_name}
            />
            <SummaryData
              label="Date of Birth*"
              value={moment(nomineeFormData[member?.value].nominee_dob).format(
                "DD/MM/YYYY"
              )}
            />
            <SummaryData
              label="Relation with Proposer*"
              value={
                nomineeFormData[member?.value].nominee_relation_with_proposer
              }
            />
          </>
        ))}
      </Summary>
    );
  }
  if (index > active) {
    return (
      <Paper
        sx={{
          m: 2,
          backgroundColor: "#Fff",
        }}
      >
        <Typography fontSize={"22px"} p={2}>
          Nominee Details
        </Typography>
      </Paper>
    );
  }
  return (
    <>
      <Paper sx={{ m: 2, backgroundColor: "#F0F4F5" }}>
        <Formik
          initialValues={nomineeFormData ? nomineeFormData : initialValues}
          validationSchema={validationMembersSchema}
          onSubmit={(values) => {
            const payload = {
              ...values,
              self: {
                ...values.self,
                nominee_relation_id: relations.find(
                  (item) =>
                    item.code === values.self.nominee_relation_with_proposer
                ).id,
              },
            };
            storeProposal({
              primary_proposer_id,
              step: 3,
              additional_data: { ...proposalData, nominee: payload },
              nominee_details: [JSON.stringify(...Object.values(payload))],
            });
            dispatch(setProposalData({ nominee: payload }));
          }}
        >
          <Form noValidate>
            <Card>
              <Title>Nominee Details </Title>
              {members.map((member) => (
                <>
                  <Box px={3} py={2}>
                    {members.length > 1 && (
                      <Typography variant="h4" gutterBottom>
                        {member?.label}
                      </Typography>
                    )}
                    <Grid container spacing={2}>
                      <Grid item xs={12} md={4}>
                        <TextInput
                          name={`${member?.value}.nominee_name`}
                          label="Nominee Name"
                          onInput={allowFullName}
                          required
                        />
                      </Grid>
                      <Grid item xs={12} md={4}>
                        <DatePicker
                          name={`${member?.value}.nominee_dob`}
                          label="Date of Birth"
                          views={["year", "month", "day"]}
                          openTo={"year"}
                          maxDate={getMaxDateByAge(18)}
                          minDate={getMinDateByAge(99)}
                          selectOnly={true}
                          placeholder="Date of Birth"
                          required
                        />
                      </Grid>
                      <Grid item xs={12} md={4}>
                        <Select
                          fixedLabel={false}
                          name={`${member?.value}.nominee_relation_with_proposer`}
                          label="Relation with Proposer"
                          options={relations}
                          selectOnly={true}
                          required
                        />
                      </Grid>
                    </Grid>
                  </Box>
                </>
              ))}
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "flex-end",
                  alignItems: "center",
                  p: 2,
                }}
              >
                <Button
                  fullWidth={isMobile ? true : false}
                  sx={{
                    textTransform: "none",
                    fontSize: isMobile ? "13px" : "16px",
                    p: 2,
                    pl: 4,
                    pr: 4,
                    borderRadius: 1,
                  }}
                  variant="contained"
                  color="secondary"
                  type="submit"
                >
                  {isLoading ? <ButtonLoader /> : "Proceed to Address"}
                </Button>
              </Box>
            </Card>
          </Form>
        </Formik>
      </Paper>
    </>
  );
};

export default NomineeDetails;
